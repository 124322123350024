import React, { Component } from "react";
import { Spin, message } from "antd";
import axios from "axios";
import URLs from "../../utils/apis";

export default class PostSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "",
      sub_title: "",
      button_text: "",
      button_link: "",
      loading: false,
    };
  }
  componentDidMount() {
    this.get_details();
  }
  get_details = () => {
    this.setState({ loading: true });
    axios
      .get(URLs.get_post_settings)
      .then((response) => {
        if (response.data.status == "1") {
          let currentObj = response.data.data;
          this.setState({
            title: currentObj.title,
            sub_title: currentObj.sub_title,
            button_text: currentObj.button_text,
            loading: false,
          });
        } else {
          this.setState({ loading: false });
          return;
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
        console.error("Warning:", error);
        // message.warning("Please try again.");
      });
  };
  save = () => {
    if (!this.state.sub_title) {
      message.warning("Sub  Title is required");
      return;
    }
    if (!this.state.title) {
      message.warning("Title is required");
      return;
    }
    if (!this.state.button_text) {
      message.warning("Button text is required");
      return;
    }
    this.setState({ loading: true });
    let formdata = new FormData();
    formdata.append("title", this.state.title);
    formdata.append("sub_title", this.state.sub_title);
    formdata.append("button_text", this.state.button_text);
    axios
      .post(URLs.save_post_setting, formdata)
      .then((response) => {
        if (response.data.status == "1") {
          message.success(response.data.message);
          this.setState(
            {
              loading: false,
            },
            () => this.get_details()
          );
        } else {
          message.warning(response.data.message);
          this.setState({ loading: false });
          return;
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
        console.error("Warning:", error);
        // message.warning("Please try again.");
      });
  };

  render() {
    return (
      <div class="card">
        <div class="card-header">
          <h4 class="card-title" style={{ fontSize: 16, marginBottom: 0 }}>
            Blog Post Settings
          </h4>
        </div>
        <Spin spinning={this.state.loading}>
          <div class="card-body p-4">
            <div class="row">
              <div class="col-lg-12">
                <div>
                  <div class="mb-3">
                    <label for="example-text-input" class="form-label">
                      Sub Title <span class="text-danger">*</span>
                    </label>
                    <input
                      class="form-control"
                      type="text"
                      value={this.state.sub_title}
                      onChange={(e) =>
                        this.setState({ sub_title: e.target.value })
                      }
                    />
                  </div>
                  <div class="mb-3">
                    <label for="example-text-input" class="form-label">
                      Title <span class="text-danger">*</span>
                    </label>
                    <input
                      class="form-control"
                      type="text"
                      value={this.state.title}
                      onChange={(e) => this.setState({ title: e.target.value })}
                    />
                  </div>
                  <div class="mb-3">
                    <label for="example-text-input" class="form-label">
                      Button Text <span class="text-danger">*</span>
                    </label>
                    <input
                      class="form-control"
                      type="text"
                      value={this.state.button_text}
                      onChange={(e) =>
                        this.setState({ button_text: e.target.value })
                      }
                    />
                  </div>
                  <div class="mt-4">
                    <button
                      type="button"
                      onClick={() => this.save()}
                      class="btn btn-primary w-md"
                    >
                      Update
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Spin>
      </div>
    );
  }
}
