import React, { Component } from "react";
import { Spin, message, Table, Modal } from "antd";
import axios from "axios";
import URLs from "../../utils/apis";
import FullEditor from "ckeditor5-build-full";
import { CKEditor } from "@ckeditor/ckeditor5-react";

export default class Services extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "",
      description: "",
      input_placeholder: "",
      loading: false,
      listLoader: false,
      service_list: [],

      add_modal_status: false,
      service_name: "",
      service_description: "",
      short_description: "",
      service_image: "",
      icon_img: "",
      formloader: false,

      service_id: 0,
      edit_modal_status: false,
      edit_service_name: "",
      edit_service_description: "",
      edit_short_description: "",
      edit_service_image: "",
      edit_icon_img: "",
      service_image_url: "",
      is_image_present: false,

      delete_modal_status: false,
    };
  }
  componentDidMount() {
    this.get_details();
    this.get_service_list();
  }
  get_details = () => {
    this.setState({ loading: true });
    axios
      .get(URLs.get_service_setting_details)
      .then((response) => {
        if (response.data.status == "1") {
          let currentObj = response.data.data;
          this.setState({
            title: currentObj.title,
            description: currentObj.description,
            loading: false,
          });
        } else {
          this.setState({ loading: false });
          return;
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
        console.error("Warning:", error);
        // message.warning("Please try again.");
      });
  };
  save_settings = () => {
    if (!this.state.title) {
      message.warning("Title is required");
      return;
    }
    if (!this.state.description) {
      message.warning("Description is required");
      return;
    }
    this.setState({ loading: true });
    let params = {
      title: this.state.title,
      description: this.state.description,
    };
    axios
      .post(URLs.save_service_section_settings, params)
      .then((response) => {
        if (response.data.status == "1") {
          message.success(response.data.message);
          this.setState(
            {
              loading: false,
            },
            () => this.get_details()
          );
        } else {
          message.warning(response.data.message);
          this.setState({ loading: false });
          return;
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
        console.error("Warning:", error);
        // message.warning("Please try again.");
      });
  };
  get_service_list = () => {
    this.setState({ listLoader: true });
    axios
      .get(URLs.get_service_list)
      .then((response) => {
        if (response.data.status == "1") {
          let currentObj = response.data.data;
          this.setState({
            service_list: response.data.data,
            listLoader: false,
          });
        } else {
          this.setState({ listLoader: false });
          return;
        }
      })
      .catch((error) => {
        this.setState({ listLoader: false });
      });
  };
  save_service = () => {
    if (!this.state.service_name) {
      message.warning("Service name is required");
      return;
    }
    if (!this.state.short_description) {
      message.warning("Short Service description is required");
      return;
    }
    if (!this.state.service_description) {
      message.warning("Long Service description is required");
      return;
    }
    if (!this.state.service_image) {
      message.warning("Please select Service image");
      return;
    }
    if (!this.state.icon_img) {
      message.warning("Please select Icon for service");
      return;
    }
    this.setState({ formloader: true });
    let formData = new FormData();
    formData.append("name", this.state.service_name);
    formData.append("description", this.state.service_description);
    formData.append("short_description", this.state.short_description);
    formData.append("service_image", this.state.service_image);
    formData.append("icon_img", this.state.icon_img);
    axios
      .post(URLs.save_service, formData)
      .then((response) => {
        if (response.data.status == "1") {
          message.success(response.data.message);
          this.setState(
            {
              formloader: false,
              service_name: "",
              service_description: "",
              short_description: "",
              add_modal_status: false,
              service_image: "",
              icon_img: "",
            },
            () => this.get_service_list()
          );
        } else {
          message.warning(response.data.message);
          this.setState({ formloader: false });
          return;
        }
      })
      .catch((error) => {
        this.setState({ formloader: false });
        console.error("Warning:", error);
        // message.warning("Please try again.");
      });
  };
  open_add_modal = () => {
    this.setState({
      add_modal_status: true,
      service_name: "",
      service_description: "",
      service_image: "",
    });
  };
  close_save_modal = () => {
    this.setState({
      add_modal_status: false,
      service_name: "",
      service_description: "",
      service_image: "",
    });
  };
  open_edit_modal = (item) => {
    let url = URLs.image_url + item.image;
    let icon_img_url = URLs.image_url + item.icon_img;
    this.setState({
      edit_modal_status: true,
      edit_service_name: item.name,
      edit_service_description: item.description,
      edit_short_description: item.short_description,
      service_image_url: url,
      service_icon_image_url: icon_img_url,
      service_id: item.service_id,
      is_image_present: item.image ? true : false,
      is_icon_image_present: item.icon_img ? true : false,
    });
  };
  close_update_modal = () => {
    this.setState({
      edit_modal_status: false,
      edit_service_name: "",
      edit_service_description: "",
      service_image_url: "",
      service_id: "",
      is_image_present: false,
      is_icon_image_present: false,
    });
  };
  update_service = () => {
    if (!this.state.edit_service_name) {
      message.warning("Service name is required");
      return;
    }
    if (!this.state.edit_service_description) {
      message.warning("Service description is required");
      return;
    }
    this.setState({ formloader: true });
    let formData = new FormData();
    formData.append("name", this.state.edit_service_name);
    formData.append("description", this.state.edit_service_description);
    formData.append("short_description", this.state.edit_short_description);
    formData.append("service_image", this.state.edit_service_image);
    formData.append("icon_img", this.state.edit_icon_img);
    formData.append("service_id", this.state.service_id);
    axios
      .post(URLs.update_service, formData)
      .then((response) => {
        if (response.data.status == "1") {
          message.success(response.data.message);
          this.setState(
            {
              formloader: false,
              edit_service_image: "",
              edit_service_description: "",
              edit_modal_status: false,
              service_image: "",
              service_id: 0,
            },
            () => this.get_service_list()
          );
        } else {
          message.warning(response.data.message);
          this.setState({ formloader: false });
          return;
        }
      })
      .catch((error) => {
        this.setState({ formloader: false });
        console.error("Warning:", error);
      });
  };
  close_delete_modal = () => {
    this.setState({
      delete_modal_status: false,
      service_id: "",
    });
  };
  open_delete_modal = (item) => {
    this.setState({
      delete_modal_status: true,
      service_id: item.service_id,
    });
  };
  delete_service = () => {
    let params = {
      service_id: this.state.service_id,
    };
    axios
      .post(URLs.delete_service, params)
      .then((response) => {
        if (response.data.status == "1") {
          message.success(response.data.message);
          this.setState(
            {
              formloader: false,
              delete_modal_status: false,
              service_id: 0,
            },
            () => this.get_service_list()
          );
        } else {
          message.warning(response.data.message);
          this.setState({ formloader: false });
          return;
        }
      })
      .catch((error) => {
        this.setState({ formloader: false });
        console.error("Warning:", error);
      });
  };
  render() {
    const columns = [
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
        width: 150,
      },
      {
        title: "Short Description",
        dataIndex: "desc",
        key: "desc",
      },
      {
        title: "Action",
        dataIndex: "action",
        key: "action",
        render: (item) => {
          return (
            <div>
              <button
                type="button"
                onClick={() => this.open_edit_modal(item)}
                class="btn btn-primary mb-3"
                style={{ width: 50, height: 35 }}
              >
                <box-icon
                  name="edit-alt"
                  color="#ffffff"
                  size={"18px"}
                ></box-icon>
              </button>
              <button
                type="button"
                onClick={() => this.open_delete_modal(item)}
                class="btn btn-danger mb-3"
                style={{ width: 50, height: 35 }}
              >
                <box-icon name="trash" color="white" size={"18px"}></box-icon>
              </button>
            </div>
          );
        },
      },
    ];
    const dataSource = this.state.service_list.map((item, index) => {
      return {
        name: item.name ? item.name : "---",
        desc: item.short_description ? item.short_description : "---",
        action: item,
      };
    });
    return (
      <div class="card">
        <div class="card-header">
          <h4 class="card-title" style={{ fontSize: 16, marginBottom: 0 }}>
            Services
          </h4>
        </div>
        <Spin spinning={this.state.loading}>
          <div class="card-body p-4">
            <div class="row">
              <div class="col-lg-12">
                <div>
                  <div class="mb-3">
                    <label for="example-text-input" class="form-label">
                      Title <span class="text-danger">*</span>
                    </label>
                    <input
                      class="form-control"
                      type="text"
                      value={this.state.title}
                      onChange={(e) => this.setState({ title: e.target.value })}
                    />
                  </div>
                  <div class="mb-3">
                    <label for="example-search-input" class="form-label">
                      Description <span class="text-danger">*</span>
                    </label>
                    <textarea
                      class="form-control"
                      rows="3"
                      placeholder=""
                      name="detail"
                      id="detail"
                      spellcheck="false"
                      value={this.state.description}
                      onChange={(e) =>
                        this.setState({ description: e.target.value })
                      }
                    >
                      {this.state.description}
                    </textarea>
                  </div>
                  <div class="mt-4">
                    <button
                      type="button"
                      onClick={() => this.save_settings()}
                      class="btn btn-primary w-md"
                    >
                      Update
                    </button>
                  </div>
                </div>
                <div class="card mt-3 p-3">
                  <button
                    type="button"
                    onClick={() => this.open_add_modal()}
                    class="btn btn-primary mb-3"
                    style={{ width: 180, float: "right", marginLeft: "auto" }}
                  >
                    Add New Service
                  </button>
                  <Table
                    bordered
                    loading={this.state.listLoader}
                    dataSource={dataSource}
                    columns={columns}
                  />
                </div>
              </div>
            </div>
          </div>
        </Spin>

        {/* Add Service Modal  */}
        <Modal
          title="Add New Service"
          visible={this.state.add_modal_status}
          onOk={() => this.save_service()}
          onCancel={() => this.close_save_modal()}
          okText="Save"
          cancelText="Cancel"
        >
          <Spin spinning={this.state.formloader}>
            <div class="py-4">
              <div class="mb-3">
                <label for="example-text-input" class="form-label">
                  Title <span class="text-danger">*</span>
                </label>
                <input
                  class="form-control"
                  type="text"
                  value={this.state.service_name}
                  onChange={(e) =>
                    this.setState({ service_name: e.target.value })
                  }
                />
              </div>
              <div class="mb-3">
                <label for="example-search-input" class="form-label">
                  Short Description <span class="text-danger">*</span>
                </label>
                <textarea
                  class="form-control"
                  rows="3"
                  placeholder=""
                  name="detail"
                  id="detail"
                  spellcheck="false"
                  value={this.state.short_description}
                  onChange={(e) =>
                    this.setState({ short_description: e.target.value })
                  }
                ></textarea>
              </div>
              <div class="mb-3">
                <label for="example-search-input" class="form-label">
                  Long Description <span class="text-danger">*</span>
                </label>
                {/* <textarea
                  class="form-control"
                  rows="3"
                  placeholder=""
                  name="detail"
                  id="detail"
                  spellcheck="false"
                  value={this.state.service_description}
                  onChange={(e) =>
                    this.setState({ service_description: e.target.value })
                  }
                ></textarea> */}
                <CKEditor
                  data={this.state.service_description}
                  editor={FullEditor}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    this.setState({ service_description: data });
                  }}
                />
              </div>
              <div class="mb-3">
                <label for="example-text-input" class="form-label">
                  Icon <span class="text-danger">*</span>
                </label>
                <input
                  class="form-control"
                  type="file"
                  onChange={(e) =>
                    this.setState({ icon_img: e.target.files[0] })
                  }
                />
              </div>
              <div class="mb-3">
                <label for="example-text-input" class="form-label">
                  Hero Image <span class="text-danger">*</span>
                </label>
                <input
                  class="form-control"
                  type="file"
                  onChange={(e) =>
                    this.setState({ service_image: e.target.files[0] })
                  }
                />
              </div>
            </div>
          </Spin>
        </Modal>

        {/* Edit Service Modal  */}
        <Modal
          title="Update Service"
          visible={this.state.edit_modal_status}
          onOk={() => this.update_service()}
          onCancel={() => this.close_update_modal()}
          okText="Update"
          cancelText="Cancel"
        >
          <Spin spinning={this.state.formloader}>
            <div class="py-4">
              <div class="mb-3">
                <label for="example-text-input" class="form-label">
                  Title <span class="text-danger">*</span>
                </label>
                <input
                  class="form-control"
                  type="text"
                  value={this.state.edit_service_name}
                  onChange={(e) =>
                    this.setState({ edit_service_name: e.target.value })
                  }
                />
              </div>
              <div class="mb-3">
                <label for="example-search-input" class="form-label">
                  Short Description <span class="text-danger">*</span>
                </label>
                <textarea
                  class="form-control"
                  rows="3"
                  placeholder=""
                  name="detail"
                  id="detail"
                  spellcheck="false"
                  value={this.state.edit_short_description}
                  onChange={(e) =>
                    this.setState({ edit_short_description: e.target.value })
                  }
                ></textarea>
              </div>
              <div class="mb-3">
                <label for="example-search-input" class="form-label">
                  Long Description <span class="text-danger">*</span>
                </label>
                {/* <textarea
                  class="form-control"
                  rows="3"
                  placeholder=""
                  name="detail"
                  id="detail"
                  spellcheck="false"
                  value={this.state.edit_service_description}
                  onChange={(e) =>
                    this.setState({ edit_service_description: e.target.value })
                  }
                ></textarea> */}
                <CKEditor
                  data={this.state.edit_service_description}
                  editor={FullEditor}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    this.setState({ edit_service_description: data });
                  }}
                />
              </div>
              <div class="mb-3">
                <label for="example-text-input" class="form-label">
                  Icon <span class="text-danger">*</span>
                </label>
                {this.state.is_icon_image_present == true && (
                  <div
                    style={{
                      padding: 10,
                      border: "1px solid #ddd",
                      width: "fit-content",
                    }}
                    class="mb-2"
                  >
                    <a href={this.state.service_icon_image_url} target="_blank">
                      <img
                        src={this.state.service_icon_image_url}
                        style={{ width: 45 }}
                      />
                    </a>
                  </div>
                )}
                <input
                  class="form-control"
                  type="file"
                  onChange={(e) =>
                    this.setState({ edit_icon_img: e.target.files[0] })
                  }
                />
              </div>
              <div class="mb-3">
                <label for="example-text-input" class="form-label">
                  Hero Image <span class="text-danger">*</span>
                </label>
                {this.state.is_image_present == true && (
                  <div
                    style={{
                      padding: 10,
                      border: "1px solid #ddd",
                      width: "fit-content",
                    }}
                    class="mb-2"
                  >
                    <a href={this.state.service_image_url} target="_blank">
                      <img
                        src={this.state.service_image_url}
                        style={{ width: 100 }}
                      />
                    </a>
                  </div>
                )}
                <input
                  class="form-control"
                  type="file"
                  onChange={(e) =>
                    this.setState({ edit_service_image: e.target.files[0] })
                  }
                />
              </div>
            </div>
          </Spin>
        </Modal>

        <Modal
          title="Delete Service"
          visible={this.state.delete_modal_status}
          onOk={() => this.delete_service()}
          onCancel={() => this.close_delete_modal()}
          okText="Delete"
          cancelText="Cancel"
        >
          <Spin spinning={this.state.formloader}>
            <div class="pt-4">
              <p style={{ fontSize: 16 }}>Are you sure you want to delete?</p>
            </div>
          </Spin>
        </Modal>
      </div>
    );
  }
}
