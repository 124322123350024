import React, { Component } from "react";
import { Modal } from "antd";

export default class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalStatus: false,
    };
  }
  componentDidMount() {
    if (!localStorage.getItem("usecret_id")) {
      localStorage.removeItem("usecret_id");
      localStorage.removeItem("usecret_name");
      window.location.href = "/";
    }
  }
  logout = () => {
    localStorage.removeItem("usecret_id");
    localStorage.removeItem("usecret_name");
    window.location.assign("/");
  };
  openModal = () => {
    this.setState({
      modalStatus: true,
    });
  };
  closeModal = () => {
    this.setState({
      modalStatus: false,
    });
  };
  render() {
    return (
      <header id="page-topbar">
        <div class="navbar-header">
          <div class="d-flex">
            <div class="navbar-brand-box">
              <a href="/dashboard" class="logo logo-dark">
                <span class="logo-sm">
                  <img
                    src="assets/images/dashboard-logo.webp"
                    alt=""
                    height="24"
                  />
                </span>
                <span class="logo-lg">
                  <img
                    src="assets/images/dashboard-logo.webp"
                    alt=""
                    height="24"
                  />{" "}
                  <span class="logo-txt">Urbanspell</span>
                </span>
              </a>

              <a href="/dashboard" class="logo logo-light">
                <span class="logo-sm">
                  <img
                    src="assets/images/dashboard-logo.webp"
                    alt=""
                    height="24"
                  />
                </span>
                <span class="logo-lg">
                  <img
                    src="assets/images/dashboard-logo.webp"
                    alt=""
                    height="24"
                  />{" "}
                  <span class="logo-txt">Minia</span>
                </span>
              </a>
            </div>

            <button
              type="button"
              class="btn btn-sm px-3 font-size-16 header-item"
              id="vertical-menu-btn"
            >
              <i
                style={{
                  position: "relative",
                  left: -5,
                  top: 4,
                }}
              >
                <box-icon name="menu"></box-icon>
              </i>
            </button>

            <form class="app-search d-none d-lg-block">
              <div class="position-relative"></div>
            </form>
          </div>

          <div class="d-flex">
            <div class="dropdown d-inline-block d-lg-none ms-2">
              <button
                type="button"
                class="btn header-item"
                id="page-header-search-dropdown"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i data-feather="search" class="icon-lg"></i>
              </button>
              <div
                class="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
                aria-labelledby="page-header-search-dropdown"
              ></div>
            </div>
            <div class="dropdown d-inline-block">
              <button
                type="button"
                class="btn header-item bg-light-subtle border-start border-end"
                id="page-header-user-dropdown"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <img
                  class="rounded-circle header-profile-user"
                  src="assets/images/users/avatar-1.jpg"
                  alt="Header Avatar"
                />
                <span class="d-none d-xl-inline-block ms-1 fw-medium">
                  Admin
                </span>
                <i class="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
              </button>
              <div class="dropdown-menu dropdown-menu-end">
                <a class="dropdown-item" href="/change-password">
                  <i class="mdi mdi mdi-face-man font-size-16 align-middle me-1"></i>{" "}
                  Change Password
                </a>
                <div class="dropdown-divider"></div>
                <a
                  class="dropdown-item"
                  href="#!"
                  onClick={() => this.openModal()}
                >
                  <i class="mdi mdi-logout font-size-16 align-middle me-1"></i>{" "}
                  Logout
                </a>
              </div>
            </div>
          </div>
        </div>

        <Modal
          visible={this.state.modalStatus}
          onCancel={() => this.closeModal()}
          onOk={() => this.logout()}
        >
          <p style={{ padding: "30px 0 0 0", fontSize: 16 }}>
            Are you sure you want to logout?
          </p>
        </Modal>
      </header>
    );
  }
}
