import React, { Component } from "react";
import { Spin, message, Table, Modal } from "antd";
import axios from "axios";
import URLs from "../../utils/apis";
import { TagsInput } from "react-tag-input-component";
import Select from "react-select";

const options = [
  {
    value: "chocolate",
    label: "Chocolate",
    image: "https://path/to/chocolate.png",
  },
  {
    value: "strawberry",
    label: "Strawberry",
    image: "https://path/to/strawberry.png",
  },
  { value: "vanilla", label: "Vanilla", image: "https://path/to/vanilla.png" },
];

const CustomOption = (props) => {
  return (
    <div {...props.innerProps} className="custom-option">
      <img
        src={props.data.image}
        alt={props.data.label}
        style={{ width: 20, height: 20, marginRight: 10 }}
      />
      {props.data.label}
    </div>
  );
};

export default class SocialLinks extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "",
      loading: false,
      listLoader: false,
      records: [],

      add_modal_status: false,

      social_icon_id: "",
      social_link: "",

      edit_social_icon_id: "",
      edit_social_link: "",
      social_id: "",

      edit_client_name: "",
      edit_description: "",
      edit_company_name: "",
      edit_image: "",

      formloader: false,

      social_id: 0,
      edit_modal_status: false,

      image_url: "",
      is_image_present: false,

      delete_modal_status: false,
      social_icons: [],
      options: [],
    };
  }
  componentDidMount() {
    this.get_records();
    this.get_social_icon_list();
  }

  get_social_icon_list = () => {
    this.setState({ loading: true });
    axios
      .get(URLs.get_social_icon_list)
      .then((response) => {
        if (response.data.status == "1") {
          let list = response.data.data;
          console.log("social list", list);
          let arr = [];
          for (let item of list) {
            let obj = {
              value: item.icon_id,
              label: item.icon_name,
              image: URLs.image_url + item.icon_image,
            };
            arr.push(obj);
          }
          this.setState({
            options: arr,
            loading: false,
          });
        } else {
          this.setState({ loading: false });
          return;
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
        console.error("Warning:", error);
        // message.warning("Please try again.");
      });
  };
  get_records = () => {
    this.setState({ listLoader: true });
    axios
      .get(URLs.get_socialmedia_list)
      .then((response) => {
        if (response.data.status == "1") {
          this.setState({
            records: response.data.data,
            listLoader: false,
          });
        } else {
          this.setState({ listLoader: false });
          return;
        }
      })
      .catch((error) => {
        this.setState({ listLoader: false });
      });
  };

  save_socialmedia = () => {
    if (!this.state.social_icon_id) {
      message.warning("Please select social media icon");
      return;
    }
    if (!this.state.social_link) {
      message.warning("Social media link is required");
      return;
    }
    this.setState({ formloader: true });
    let params = {
      social_icon_id: this.state.social_icon_id.value,
      social_link: this.state.social_link,
    };
    axios
      .post(URLs.save_socialmedia, params)
      .then((response) => {
        if (response.data.status == "1") {
          message.success(response.data.message);
          this.setState(
            {
              formloader: false,
              add_modal_status: false,
              social_icon_id: "",
              social_link: "",
            },
            () => this.get_records()
          );
        } else {
          message.warning(response.data.message);
          this.setState({ formloader: false });
          return;
        }
      })
      .catch((error) => {
        this.setState({ formloader: false });
        console.error("Warning:", error);
        // message.warning("Please try again.");
      });
  };
  open_add_modal = () => {
    this.setState({
      add_modal_status: true,
      social_icon_id: "",
      social_link: "",
      social_id: "",
    });
  };
  close_save_modal = () => {
    this.setState({
      add_modal_status: false,
      social_icon_id: "",
      social_link: "",
      social_id: "",
    });
  };
  open_edit_modal = (item) => {
    let social_icon_selcted = {};
    for (let single of this.state.options) {
      if (single.value == item.social_icon_id) {
        social_icon_selcted = single;
      }
    }
    this.setState({
      edit_modal_status: true,
      edit_social_icon_id: social_icon_selcted,
      edit_social_link: item.social_link,
      social_id: item.social_id,
    });
  };
  close_update_modal = () => {
    this.setState({
      edit_modal_status: false,
      edit_social_icon_id: "",
      edit_social_link: "",
      edit_social_id: "",
    });
  };
  close_save_modal = () => {
    this.setState({
      add_modal_status: false,
      social_icon_id: "",
      social_link: "",
      social_id: "",
    });
  };
  update_socialmedia = () => {
    if (!this.state.edit_social_icon_id) {
      message.warning("Please select social icon");
      return;
    }
    if (!this.state.edit_social_link) {
      message.warning("Social link is required");
      return;
    }
    this.setState({ formloader: true });
    let params = {
      social_icon_id: this.state.edit_social_icon_id.value,
      social_link: this.state.edit_social_link,
      social_id: this.state.social_id,
    };
    axios
      .post(URLs.update_socialmedia, params)
      .then((response) => {
        if (response.data.status == "1") {
          message.success(response.data.message);
          this.setState(
            {
              formloader: false,
              edit_modal_status: false,
              edit_social_icon_id: "",
              edit_social_link: "",
              social_id: "",
            },
            () => this.get_records()
          );
        } else {
          message.warning(response.data.message);
          this.setState({ formloader: false });
          return;
        }
      })
      .catch((error) => {
        this.setState({ formloader: false });
        console.error("Warning:", error);
        // message.warning("Please try again.");
      });
  };
  close_delete_modal = () => {
    this.setState({
      delete_modal_status: false,
      social_id: "",
    });
  };
  open_delete_modal = (item) => {
    this.setState({
      delete_modal_status: true,
      social_id: item.social_id,
    });
  };
  delete_project = () => {
    let params = {
      social_id: this.state.social_id,
    };
    axios
      .post(URLs.delete_socialmedia, params)
      .then((response) => {
        if (response.data.status == "1") {
          message.success(response.data.message);
          this.setState(
            {
              formloader: false,
              delete_modal_status: false,
              social_id: 0,
            },
            () => this.get_records()
          );
        } else {
          message.warning(response.data.message);
          this.setState({ formloader: false });
          return;
        }
      })
      .catch((error) => {
        this.setState({ formloader: false });
        console.error("Warning:", error);
      });
  };
  handleChange = (option) => {
    this.setState({ social_icon_id: option });
  };
  edithandleChange = (option) => {
    this.setState({ edit_social_icon_id: option });
  };
  render() {
    const columns = [
      {
        title: "Social Media Icon",
        dataIndex: "social_icon",
        key: "social_icon",
        width: 250,
        render: (item) => {
          let url = URLs.image_url + item.icon_image;
          return (
            <div>
              <img src={url} style={{ maxWidth: 100, width: 50 }} />
            </div>
          );
        },
      },
      {
        title: "Action",
        dataIndex: "action",
        key: "action",
        render: (item) => {
          return (
            <div>
              <button
                type="button"
                onClick={() => this.open_edit_modal(item)}
                class="btn btn-primary mb-3"
                style={{ width: 50, height: 35, marginRight: 10 }}
              >
                <box-icon
                  name="edit-alt"
                  color="#ffffff"
                  size={"18px"}
                ></box-icon>
              </button>
              <button
                type="button"
                onClick={() => this.open_delete_modal(item)}
                class="btn btn-danger mb-3"
                style={{ width: 50, height: 35 }}
              >
                <box-icon name="trash" color="white" size={"18px"}></box-icon>
              </button>
            </div>
          );
        },
      },
    ];
    const dataSource = this.state.records.map((item, index) => {
      return {
        social_icon: item,
        action: item,
      };
    });
    return (
      <div class="card">
        <div class="card-header">
          <h4 class="card-title" style={{ fontSize: 16, marginBottom: 0 }}>
            Social Media Links
          </h4>
        </div>
        <Spin spinning={this.state.loading}>
          <div class="card-body p-4">
            <div class="row">
              <div class="col-lg-12">
                <div class="card mt-3 p-3">
                  <button
                    type="button"
                    onClick={() => this.open_add_modal()}
                    class="btn btn-primary mb-3"
                    style={{ width: 180, float: "right", marginLeft: "auto" }}
                  >
                    Add New Social
                  </button>
                  <Table
                    bordered
                    loading={this.state.listLoader}
                    dataSource={dataSource}
                    columns={columns}
                  />
                </div>
              </div>
            </div>
          </div>
        </Spin>

        {/* Add Service Modal  */}
        <Modal
          title="Add Social Media Link"
          visible={this.state.add_modal_status}
          onOk={() => this.save_socialmedia()}
          onCancel={() => this.close_save_modal()}
          okText="Save"
          cancelText="Cancel"
        >
          <Spin spinning={this.state.formloader}>
            <div class="py-4">
              <div class="mb-3">
                <label for="example-text-input" class="form-label">
                  Select Social Icon <span class="text-danger">*</span>
                </label>
                <Select
                  options={this.state.options}
                  components={{ Option: CustomOption }}
                  onChange={this.handleChange}
                  value={this.state.social_icon_id}
                />
              </div>
              <div class="mb-3">
                <label for="example-text-input" class="form-label">
                  Social Media Link <span class="text-danger">*</span>
                </label>
                <input
                  class="form-control"
                  type="text"
                  value={this.state.social_link}
                  onChange={(e) =>
                    this.setState({ social_link: e.target.value })
                  }
                />
              </div>
            </div>
          </Spin>
        </Modal>

        {/* Edit Service Modal  */}
        <Modal
          title="Update Social Media Icon"
          visible={this.state.edit_modal_status}
          onOk={() => this.update_socialmedia()}
          onCancel={() => this.close_update_modal()}
          okText="Update"
          cancelText="Cancel"
        >
          <Spin spinning={this.state.formloader}>
            <div class="py-4">
              <div class="mb-3">
                <label for="example-text-input" class="form-label">
                  Select Social Icon <span class="text-danger">*</span>
                </label>
                <Select
                  options={this.state.options}
                  components={{ Option: CustomOption }}
                  onChange={this.edithandleChange}
                  value={this.state.edit_social_icon_id}
                />
              </div>
              <div class="mb-3">
                <label for="example-text-input" class="form-label">
                  Social Media Link <span class="text-danger">*</span>
                </label>
                <input
                  class="form-control"
                  type="text"
                  value={this.state.edit_social_link}
                  onChange={(e) =>
                    this.setState({ edit_social_link: e.target.value })
                  }
                />
              </div>
            </div>
          </Spin>
        </Modal>

        <Modal
          title="Delete Social Media Icon"
          visible={this.state.delete_modal_status}
          onOk={() => this.delete_project()}
          onCancel={() => this.close_delete_modal()}
          okText="Delete"
          cancelText="Cancel"
        >
          <Spin spinning={this.state.formloader}>
            <div class="pt-4">
              <p style={{ fontSize: 16 }}>Are you sure you want to delete?</p>
            </div>
          </Spin>
        </Modal>
      </div>
    );
  }
}
