import React, { Component } from "react";
import { Spin, message, Table, Modal } from "antd";
import axios from "axios";
import URLs from "../../utils/apis";
import { TagsInput } from "react-tag-input-component";

export default class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contact_link: "",
      contact1: "",
      contact2: "",
      email1: "",
      email2: "",
      contact_address: "",
      loading: false,
    };
  }
  componentDidMount() {
    this.get_details();
  }
  get_details = () => {
    this.setState({ loading: true });
    axios
      .get(URLs.get_header_settings)
      .then((response) => {
        if (response.data.status == "1") {
          let currentObj = response.data.data;
          this.setState({
            contact_link: currentObj.contact_map_link,
            contact1: currentObj.contact1,
            contact2: currentObj.contact2,
            email1: currentObj.email1,
            email2: currentObj.email2,
            contact_address: currentObj.contact_address,
            loading: false,
          });
        } else {
          this.setState({ loading: false });
          return;
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
        console.error("Warning:", error);
        // message.warning("Please try again.");
      });
  };
  save_settings = () => {
    if (!this.state.contact_link) {
      message.warning("Please enter google map link.");
      return;
    }
    this.setState({ loading: true });
    let params = {
      contact_link: this.state.contact_link,
      contact1: this.state.contact1,
      contact2: this.state.contact2,
      email1: this.state.email1,
      email2: this.state.email2,
      contact_address: this.state.contact_address,
    };
    axios
      .post(URLs.save_contact_settings, params)
      .then((response) => {
        if (response.data.status == "1") {
          message.success(response.data.message);
          this.setState(
            {
              loading: false,
            },
            () => this.get_details()
          );
        } else {
          message.warning(response.data.message);
          this.setState({ loading: false });
          return;
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
        console.error("Warning:", error);
        // message.warning("Please try again.");
      });
  };

  render() {
    return (
      <div class="card">
        <div class="card-header">
          <h4 class="card-title" style={{ fontSize: 16, marginBottom: 0 }}>
            Footer Settings
          </h4>
        </div>
        <Spin spinning={this.state.loading}>
          <div class="card-body p-4">
            <div class="row">
              <div class="col-lg-12">
                <div class="row">
                  <div class="mb-3">
                    <label for="example-text-input" class="form-label">
                      Contact Google Map Link{" "}
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      class="form-control"
                      type="text"
                      value={this.state.contact_link}
                      onChange={(e) =>
                        this.setState({ contact_link: e.target.value })
                      }
                    />
                  </div>
                  <div class="mb-3 col-lg-6">
                    <label for="example-text-input" class="form-label">
                      Contact No 1 <span className="text-danger">*</span>
                    </label>
                    <input
                      class="form-control"
                      type="text"
                      value={this.state.contact1}
                      onChange={(e) =>
                        this.setState({ contact1: e.target.value })
                      }
                    />
                  </div>
                  <div class="mb-3 col-lg-6">
                    <label for="example-text-input" class="form-label">
                      Contact No 2 <span className="text-danger">*</span>
                    </label>
                    <input
                      class="form-control"
                      type="text"
                      value={this.state.contact2}
                      onChange={(e) =>
                        this.setState({ contact2: e.target.value })
                      }
                    />
                  </div>
                  <div class="mb-3 col-lg-6">
                    <label for="example-text-input" class="form-label">
                      Email No 1 <span className="text-danger">*</span>
                    </label>
                    <input
                      class="form-control"
                      type="text"
                      value={this.state.email1}
                      onChange={(e) =>
                        this.setState({ email1: e.target.value })
                      }
                    />
                  </div>
                  <div class="mb-3 col-lg-6">
                    <label for="example-text-input" class="form-label">
                      Email No 1 <span className="text-danger">*</span>
                    </label>
                    <input
                      class="form-control"
                      type="text"
                      value={this.state.email2}
                      onChange={(e) =>
                        this.setState({ email2: e.target.value })
                      }
                    />
                  </div>
                  <div class="mb-3 col-lg-6">
                    <label for="example-text-input" class="form-label">
                      Address <span className="text-danger">*</span>
                    </label>
                    <input
                      class="form-control"
                      type="text"
                      value={this.state.contact_address}
                      onChange={(e) =>
                        this.setState({ contact_address: e.target.value })
                      }
                    />
                  </div>
                  <div class="mt-4">
                    <button
                      type="button"
                      onClick={() => this.save_settings()}
                      class="btn btn-primary w-md"
                    >
                      Update
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Spin>
      </div>
    );
  }
}
