import React from "react";
import "boxicons";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Redirect,
} from "react-router-dom";
import Login from "./app/Login";
import Dashboard from "./app/Dashboard";
import BlogPosts from "./app/BlogPosts";
import AddPost from "./app/AddPost";
import EditPost from "./app/EditPost";
import Subscribers from "./app/Subscribers";

import PageSettings from "./app/PageSettings";
import Facebook from "./app/Facebook";
import GoogleAna from "./app/GoogleAna";
import ChangePassword from "./app/ChangePassword";
import MetaSettings from "./app/MetaSettings";

// import ImportUsers from "./app/ImportUsers";
// import Settings from "./app/Settings";

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" exact element={<Login />} />
          <Route path="/dashboard" exact element={<Dashboard />} />
          <Route path="/blog-posts" exact element={<BlogPosts />} />
          <Route path="/subscribers" exact element={<Subscribers />} />
          <Route path="/add-new-post" exact element={<AddPost />} />
          <Route path="/edit-post" element={<EditPost />} />
          {/* Pages  */}
          <Route path="/page-settings" element={<PageSettings />} />
          <Route path="/facebook" element={<Facebook />} />
          <Route path="/google-analytics" element={<GoogleAna />} />
          <Route path="/change-password" element={<ChangePassword />} />
          <Route path="/meta-tag-settings" element={<MetaSettings />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
