import React, { Component } from "react";
import Header from "./includes/Header";
import Sidebar from "./includes/Sidebar";
import Footer from "./includes/Footer";
import URLs from "./utils/apis";
import axios from "axios";
import { Table } from "antd";

// section
import Hero from "./screens/page_settings/Hero";
import Whoweare from "./screens/page_settings/Whoweare";
import Services from "./screens/page_settings/Services";
import Projects from "./screens/page_settings/Projects";
import Clients from "./screens/page_settings/Clients";
import CopyRight from "./includes/CopyRight";

export default class Facebook extends Component {
  constructor(props) {
    super(props);
    this.state = {
      total_users: 0,
      loading: false,
    };
  }
  componentDidMount() {
    this.get_data();
  }
  get_data = () => {
    this.setState({ loading: true });
    axios
      .get(URLs.get_dashboard_data)
      .then((response) => {
        if (response.data.status == "1") {
          this.setState({
            total_users: response.data.data,
            loading: false,
          });
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
        console.error("Warning:", error);
      });
  };
  render() {
    const dataSource = [
      {
        key: "1",
        name: "Mike",
        age: 32,
        address: "10 Downing Street",
      },
      {
        key: "2",
        name: "John",
        age: 42,
        address: "10 Downing Street",
      },
    ];
    const columns = [
      {
        title: "Service Name",
        dataIndex: "name",
        key: "name",
      },
      {
        title: "Description",
        dataIndex: "description",
        key: "description",
      },
      {
        title: "Date",
        dataIndex: "date",
        key: "date",
      },
      {
        title: "Action",
        dataIndex: "action",
        key: "action",
      },
    ];
    return (
      <div id="layout-wrapper">
        <Header />
        <Sidebar />
        <div class="main-content">
          <div class="page-content">
            <div class="container-fluid">
              <div class="row">
                <div class="col-12">
                  <div class="">
                    <div class="">
                      <div class="row">
                        <div class="col-md-12">
                          <iframe
                            src="https://business.facebook.com/"
                            height="480px"
                            width="100%"
                          ></iframe>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <CopyRight />
        </div>
      </div>
    );
  }
}
