import React, { Component } from "react";
import Header from "./includes/Header";
import Sidebar from "./includes/Sidebar";
import Footer from "./includes/Footer";
import URLs from "./utils/apis";
import axios from "axios";
import { Table } from "antd";

// section
import Hero from "./screens/page_settings/Hero";
import Whoweare from "./screens/page_settings/Whoweare";
import Services from "./screens/page_settings/Services";
import Projects from "./screens/page_settings/Projects";
import Clients from "./screens/page_settings/Clients";
import MeetOurTeam from "./screens/page_settings/MeetOurTeam";
import Testimonials from "./screens/page_settings/Testimonials";
import Cta from "./screens/page_settings/Cta";
import Header2 from "./screens/page_settings/Header";
import Mission from "./screens/page_settings/Mission";
import Footer2 from "./screens/page_settings/Footer";
import Countdown from "./screens/page_settings/Countdown";
import PostSettings from "./screens/page_settings/PostSettings";
import SocialLinks from "./screens/page_settings/SocialLinks";
import Contact from "./screens/page_settings/Contact";

import CopyRight from "./includes/CopyRight";

export default class PageSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      total_users: 0,
      loading: false,
    };
  }
  componentDidMount() {
    this.get_data();
  }
  get_data = () => {
    this.setState({ loading: true });
    axios
      .get(URLs.get_dashboard_data)
      .then((response) => {
        if (response.data.status == "1") {
          this.setState({
            total_users: response.data.data,
            loading: false,
          });
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
        console.error("Warning:", error);
      });
  };
  render() {
    const dataSource = [
      {
        key: "1",
        name: "Mike",
        age: 32,
        address: "10 Downing Street",
      },
      {
        key: "2",
        name: "John",
        age: 42,
        address: "10 Downing Street",
      },
    ];
    const columns = [
      {
        title: "Service Name",
        dataIndex: "name",
        key: "name",
      },
      {
        title: "Description",
        dataIndex: "description",
        key: "description",
      },
      {
        title: "Date",
        dataIndex: "date",
        key: "date",
      },
      {
        title: "Action",
        dataIndex: "action",
        key: "action",
      },
    ];
    return (
      <div id="layout-wrapper">
        <Header />
        <Sidebar />
        <div class="main-content">
          <div class="page-content">
            <div class="container-fluid">
              <div class="row">
                <div class="col-12">
                  <div class="page-title-box d-sm-flex align-items-center justify-content-between">
                    <h4 class="mb-sm-0 font-size-18">Page Settings</h4>
                    <div class="page-title-right"></div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-12">
                  <div class="card">
                    <div class="card-body">
                      <div class="row">
                        <div class="col-md-2">
                          <div
                            class="nav flex-column nav-pills"
                            id="v-pills-tab"
                            role="tablist"
                            aria-orientation="vertical"
                          >
                            <a
                              class="nav-link mb-2 active"
                              id="v-pills-hero-tab"
                              data-bs-toggle="pill"
                              href="#v-pills-hero"
                              role="tab"
                              aria-controls="v-pills-hero"
                              aria-selected="true"
                            >
                              Hero Section
                            </a>
                            <a
                              class="nav-link mb-2"
                              id="v-pills-whoare-tab"
                              data-bs-toggle="pill"
                              href="#v-pills-whoare"
                              role="tab"
                              aria-controls="v-pills-whoare"
                              aria-selected="false"
                            >
                              Who We Are
                            </a>
                            <a
                              class="nav-link mb-2"
                              id="v-pills-services-tab"
                              data-bs-toggle="pill"
                              href="#v-pills-services"
                              role="tab"
                              aria-controls="v-pills-services"
                              aria-selected="false"
                            >
                              Services
                            </a>
                            <a
                              class="nav-link"
                              id="v-pills-settings-tab"
                              data-bs-toggle="pill"
                              href="#v-pills-settings"
                              role="tab"
                              aria-controls="v-pills-settings"
                              aria-selected="false"
                            >
                              Recent Projects
                            </a>
                            <a
                              class="nav-link"
                              id="v-pills-clients-tab"
                              data-bs-toggle="pill"
                              href="#v-pills-clients"
                              role="tab"
                              aria-controls="v-pills-clients"
                              aria-selected="false"
                            >
                              Clients
                            </a>
                            <a
                              class="nav-link"
                              id="v-pills-team-tab"
                              data-bs-toggle="pill"
                              href="#v-pills-team"
                              role="tab"
                              aria-controls="v-pills-team"
                              aria-selected="false"
                            >
                              Meet Our Team
                            </a>
                            <a
                              class="nav-link"
                              id="v-pills-testimonials-tab"
                              data-bs-toggle="pill"
                              href="#v-pills-testimonials"
                              role="tab"
                              aria-controls="v-pills-testimonials"
                              aria-selected="false"
                            >
                              Testimonials
                            </a>
                            <a
                              class="nav-link"
                              id="v-pills-cta-tab"
                              data-bs-toggle="pill"
                              href="#v-pills-cta"
                              role="tab"
                              aria-controls="v-pills-cta"
                              aria-selected="false"
                            >
                              CTA
                            </a>
                            <a
                              class="nav-link"
                              id="v-pills-misssion-tab"
                              data-bs-toggle="pill"
                              href="#v-pills-misssion"
                              role="tab"
                              aria-controls="v-pills-misssion"
                              aria-selected="false"
                            >
                              Mission & Vision
                            </a>
                            <a
                              class="nav-link"
                              id="v-pills-countdown-tab"
                              data-bs-toggle="pill"
                              href="#v-pills-countdown"
                              role="tab"
                              aria-controls="v-pills-countdown"
                              aria-selected="false"
                            >
                              Countdown & Our Story
                            </a>
                            <a
                              class="nav-link"
                              id="v-pills-blogpost-tab"
                              data-bs-toggle="pill"
                              href="#v-pills-blogpost"
                              role="tab"
                              aria-controls="v-pills-blogpost"
                              aria-selected="false"
                            >
                              Blog Post
                            </a>
                            <a
                              class="nav-link"
                              id="v-pills-header-tab"
                              data-bs-toggle="pill"
                              href="#v-pills-header"
                              role="tab"
                              aria-controls="v-pills-header"
                              aria-selected="false"
                            >
                              Header
                            </a>
                            <a
                              class="nav-link"
                              id="v-pills-footer-tab"
                              data-bs-toggle="pill"
                              href="#v-pills-footer"
                              role="tab"
                              aria-controls="v-pills-footer"
                              aria-selected="false"
                            >
                              Footer
                            </a>
                            <a
                              class="nav-link"
                              id="v-pills-social-tab"
                              data-bs-toggle="pill"
                              href="#v-pills-social"
                              role="tab"
                              aria-controls="v-pills-social"
                              aria-selected="false"
                            >
                              Social Links
                            </a>
                            <a
                              class="nav-link"
                              id="v-pills-contact-tab"
                              data-bs-toggle="pill"
                              href="#v-pills-contact"
                              role="tab"
                              aria-controls="v-pills-contact"
                              aria-selected="false"
                            >
                              Contact
                            </a>
                          </div>
                        </div>
                        <div class="col-md-10">
                          <div
                            class="tab-content text-muted mt-4 mt-md-0"
                            id="v-pills-tabContent"
                          >
                            <div
                              class="tab-pane fade show active"
                              id="v-pills-hero"
                              role="tabpanel"
                              aria-labelledby="v-pills-hero-tab"
                            >
                              <Hero />
                            </div>
                            <div
                              class="tab-pane fade"
                              id="v-pills-whoare"
                              role="tabpanel"
                              aria-labelledby="v-pills-whoare-tab"
                            >
                              <Whoweare />
                            </div>
                            <div
                              class="tab-pane fade"
                              id="v-pills-services"
                              role="tabpanel"
                              aria-labelledby="v-pills-services-tab"
                            >
                              <Services />
                            </div>
                            <div
                              class="tab-pane fade"
                              id="v-pills-settings"
                              role="tabpanel"
                              aria-labelledby="v-pills-settings-tab"
                            >
                              <Projects />
                            </div>
                            <div
                              class="tab-pane fade"
                              id="v-pills-clients"
                              role="tabpanel"
                              aria-labelledby="v-pills-clients-tab"
                            >
                              <Clients />
                            </div>
                            <div
                              class="tab-pane fade"
                              id="v-pills-team"
                              role="tabpanel"
                              aria-labelledby="v-pills-team-tab"
                            >
                              <MeetOurTeam />
                            </div>
                            <div
                              class="tab-pane fade"
                              id="v-pills-testimonials"
                              role="tabpanel"
                              aria-labelledby="v-pills-testimonials-tab"
                            >
                              <Testimonials />
                            </div>
                            <div
                              class="tab-pane fade"
                              id="v-pills-cta"
                              role="tabpanel"
                              aria-labelledby="v-pills-cta-tab"
                            >
                              <Cta />
                            </div>
                            <div
                              class="tab-pane fade"
                              id="v-pills-header"
                              role="tabpanel"
                              aria-labelledby="v-pills-header-tab"
                            >
                              <Header2 />
                            </div>
                            <div
                              class="tab-pane fade"
                              id="v-pills-footer"
                              role="tabpanel"
                              aria-labelledby="v-pills-footer-tab"
                            >
                              <Footer2 />
                            </div>
                            <div
                              class="tab-pane fade"
                              id="v-pills-misssion"
                              role="tabpanel"
                              aria-labelledby="v-pills-misssion-tab"
                            >
                              <Mission />
                            </div>
                            <div
                              class="tab-pane fade"
                              id="v-pills-countdown"
                              role="tabpanel"
                              aria-labelledby="v-pills-countdown-tab"
                            >
                              <Countdown />
                            </div>
                            <div
                              class="tab-pane fade"
                              id="v-pills-blogpost"
                              role="tabpanel"
                              aria-labelledby="v-pills-blogpost-tab"
                            >
                              <PostSettings />
                            </div>
                            <div
                              class="tab-pane fade"
                              id="v-pills-social"
                              role="tabpanel"
                              aria-labelledby="v-pills-social-tab"
                            >
                              <SocialLinks />
                            </div>
                            <div
                              class="tab-pane fade"
                              id="v-pills-contact"
                              role="tabpanel"
                              aria-labelledby="v-pills-contact-tab"
                            >
                              <Contact />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <CopyRight />
        </div>
      </div>
    );
  }
}
