import React, { Component } from "react";
import { Spin, message, Table, Modal } from "antd";
import axios from "axios";
import URLs from "../../utils/apis";
import { TagsInput } from "react-tag-input-component";

export default class Testimonials extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "",
      loading: false,
      listLoader: false,
      records: [],

      add_modal_status: false,

      client_name: "",
      description: "",
      company_name: "",
      image: "",

      edit_client_name: "",
      edit_description: "",
      edit_company_name: "",
      edit_image: "",

      formloader: false,

      t_id: 0,
      edit_modal_status: false,

      image_url: "",
      is_image_present: false,

      delete_modal_status: false,
    };
  }
  componentDidMount() {
    this.get_details();
    this.get_records();
  }
  get_details = () => {
    this.setState({ loading: true });
    axios
      .get(URLs.get_testimonial_setting)
      .then((response) => {
        if (response.data.status == "1") {
          let currentObj = response.data.data;
          this.setState({
            title: currentObj.title,
            loading: false,
          });
        } else {
          this.setState({ loading: false });
          return;
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
        console.error("Warning:", error);
        // message.warning("Please try again.");
      });
  };
  save_settings = () => {
    if (!this.state.title) {
      message.warning("Title is required");
      return;
    }
    this.setState({ loading: true });
    let params = {
      title: this.state.title,
    };
    axios
      .post(URLs.save_testimonial_setting, params)
      .then((response) => {
        if (response.data.status == "1") {
          message.success(response.data.message);
          this.setState(
            {
              loading: false,
            },
            () => this.get_details()
          );
        } else {
          message.warning(response.data.message);
          this.setState({ loading: false });
          return;
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
        console.error("Warning:", error);
        // message.warning("Please try again.");
      });
  };
  get_records = () => {
    this.setState({ listLoader: true });
    axios
      .get(URLs.get_testimonial_list)
      .then((response) => {
        if (response.data.status == "1") {
          this.setState({
            records: response.data.data,
            listLoader: false,
          });
        } else {
          this.setState({ listLoader: false });
          return;
        }
      })
      .catch((error) => {
        this.setState({ listLoader: false });
      });
  };
  save_testimonial = () => {
    if (!this.state.client_name) {
      message.warning("Client name is required");
      return;
    }
    if (!this.state.company_name) {
      message.warning("Company name is required");
      return;
    }
    if (!this.state.description) {
      message.warning("Description is required");
      return;
    }
    if (!this.state.image) {
      message.warning("Please select image");
      return;
    }
    this.setState({ formloader: true });
    let formData = new FormData();
    formData.append("client_name", this.state.client_name);
    formData.append("company_name", this.state.company_name);
    formData.append("description", this.state.description);
    formData.append("image", this.state.image);

    axios
      .post(URLs.save_testimonial, formData)
      .then((response) => {
        if (response.data.status == "1") {
          message.success(response.data.message);
          this.setState(
            {
              formloader: false,
              add_modal_status: false,
              client_name: "",
              company_name: "",
              image: "",
              description: "",
            },
            () => this.get_records()
          );
        } else {
          message.warning(response.data.message);
          this.setState({ formloader: false });
          return;
        }
      })
      .catch((error) => {
        this.setState({ formloader: false });
        console.error("Warning:", error);
        // message.warning("Please try again.");
      });
  };
  open_add_modal = () => {
    this.setState({
      add_modal_status: true,
      client_name: "",
      description: "",
      company_name: "",
      image: "",
    });
  };
  close_save_modal = () => {
    this.setState({
      add_modal_status: false,
      client_name: "",
      description: "",
      company_name: "",
      image: "",
    });
  };
  open_edit_modal = (item) => {
    let banner_url = URLs.image_url + item.image;
    this.setState({
      edit_modal_status: true,
      edit_client_name: item.client_name,
      edit_description: item.description,
      edit_company_name: item.company_name,
      edit_image: "",
      image_url: banner_url,
      t_id: item.t_id,
      is_image_present: item.image ? true : false,
    });
  };
  close_update_modal = () => {
    this.setState({
      is_image_present: false,
      t_id: "",
      edit_modal_status: false,
      edit_client_name: "",
      edit_description: "",
      edit_company_name: "",
      edit_image: "",
      image_url: "",
      t_id: "",
      is_image_present: false,
    });
  };
  close_save_modal = () => {
    this.setState({
      add_modal_status: false,
      client_name: "",
      description: "",
      company_name: "",
      image: "",
    });
  };

  update_testimonial = () => {
    if (!this.state.edit_client_name) {
      message.warning("Client name is required");
      return;
    }
    if (!this.state.edit_company_name) {
      message.warning("Company name is required");
      return;
    }
    if (!this.state.edit_description) {
      message.warning("Description is required");
      return;
    }
    if (!this.state.edit_image) {
      message.warning("Please select image");
      return;
    }
    this.setState({ formloader: true });
    let formData = new FormData();
    formData.append("client_name", this.state.edit_client_name);
    formData.append("company_name", this.state.edit_company_name);
    formData.append("description", this.state.edit_description);
    formData.append("image", this.state.edit_image);
    formData.append("t_id", this.state.t_id);

    axios
      .post(URLs.update_testimonial, formData)
      .then((response) => {
        if (response.data.status == "1") {
          message.success(response.data.message);
          this.setState(
            {
              formloader: false,
              edit_modal_status: false,
              edit_client_name: "",
              edit_company_name: "",
              edit_description: "",
              edit_image: "",
              t_id: 0,
            },
            () => this.get_records()
          );
        } else {
          message.warning(response.data.message);
          this.setState({ formloader: false });
          return;
        }
      })
      .catch((error) => {
        this.setState({ formloader: false });
        console.error("Warning:", error);
        // message.warning("Please try again.");
      });
  };
  close_delete_modal = () => {
    this.setState({
      delete_modal_status: false,
      t_id: "",
    });
  };
  open_delete_modal = (item) => {
    this.setState({
      delete_modal_status: true,
      t_id: item.t_id,
    });
  };
  delete_project = () => {
    let params = {
      t_id: this.state.t_id,
    };
    axios
      .post(URLs.delete_testimonial, params)
      .then((response) => {
        if (response.data.status == "1") {
          message.success(response.data.message);
          this.setState(
            {
              formloader: false,
              delete_modal_status: false,
              t_id: 0,
            },
            () => this.get_records()
          );
        } else {
          message.warning(response.data.message);
          this.setState({ formloader: false });
          return;
        }
      })
      .catch((error) => {
        this.setState({ formloader: false });
        console.error("Warning:", error);
      });
  };

  render() {
    const columns = [
      {
        title: "Client Name",
        dataIndex: "cname",
        key: "cname",
        width: 150,
      },
      {
        title: "Company Name",
        dataIndex: "company_name",
        key: "company_name",
      },
      {
        title: "Action",
        dataIndex: "action",
        key: "action",
        render: (item) => {
          return (
            <div>
              <button
                type="button"
                onClick={() => this.open_edit_modal(item)}
                class="btn btn-primary mb-3"
                style={{ width: 50, height: 35, marginRight: 10 }}
              >
                <box-icon
                  name="edit-alt"
                  color="#ffffff"
                  size={"18px"}
                ></box-icon>
              </button>
              <button
                type="button"
                onClick={() => this.open_delete_modal(item)}
                class="btn btn-danger mb-3"
                style={{ width: 50, height: 35 }}
              >
                <box-icon name="trash" color="white" size={"18px"}></box-icon>
              </button>
            </div>
          );
        },
      },
    ];
    const dataSource = this.state.records.map((item, index) => {
      return {
        cname: item.client_name ? item.client_name : "---",
        company_name: item.company_name ? item.company_name : "---",
        action: item,
      };
    });
    return (
      <div class="card">
        <div class="card-header">
          <h4 class="card-title" style={{ fontSize: 16, marginBottom: 0 }}>
            Testimonials
          </h4>
        </div>
        <Spin spinning={this.state.loading}>
          <div class="card-body p-4">
            <div class="row">
              <div class="col-lg-12">
                <div>
                  <div class="mb-3">
                    <label for="example-text-input" class="form-label">
                      Title <span class="text-danger">*</span>
                    </label>
                    <input
                      class="form-control"
                      type="text"
                      value={this.state.title}
                      onChange={(e) => this.setState({ title: e.target.value })}
                    />
                  </div>
                  <div class="mt-4">
                    <button
                      type="button"
                      onClick={() => this.save_settings()}
                      class="btn btn-primary w-md"
                    >
                      Update
                    </button>
                  </div>
                </div>
                <div class="card mt-3 p-3">
                  <button
                    type="button"
                    onClick={() => this.open_add_modal()}
                    class="btn btn-primary mb-3"
                    style={{ width: 180, float: "right", marginLeft: "auto" }}
                  >
                    Add New Testimonial
                  </button>
                  <Table
                    bordered
                    loading={this.state.listLoader}
                    dataSource={dataSource}
                    columns={columns}
                  />
                </div>
              </div>
            </div>
          </div>
        </Spin>

        {/* Add Service Modal  */}
        <Modal
          title="Add New Testimonial"
          visible={this.state.add_modal_status}
          onOk={() => this.save_testimonial()}
          onCancel={() => this.close_save_modal()}
          okText="Save"
          cancelText="Cancel"
        >
          <Spin spinning={this.state.formloader}>
            <div class="py-4">
              <div class="mb-3">
                <label for="example-text-input" class="form-label">
                  Client Name <span class="text-danger">*</span>
                </label>
                <input
                  class="form-control"
                  type="text"
                  value={this.state.client_name}
                  onChange={(e) =>
                    this.setState({ client_name: e.target.value })
                  }
                />
              </div>
              <div class="mb-3">
                <label for="example-text-input" class="form-label">
                  Company Name <span class="text-danger">*</span>
                </label>
                <input
                  class="form-control"
                  type="text"
                  value={this.state.company_name}
                  onChange={(e) =>
                    this.setState({ company_name: e.target.value })
                  }
                />
              </div>
              <div class="mb-3">
                <label for="example-search-input" class="form-label">
                  Description <span class="text-danger">*</span>
                </label>
                <textarea
                  class="form-control"
                  rows="3"
                  placeholder=""
                  name="detail"
                  id="detail"
                  spellcheck="false"
                  value={this.state.description}
                  onChange={(e) =>
                    this.setState({ description: e.target.value })
                  }
                ></textarea>
              </div>
              <div class="mb-3">
                <label for="example-text-input" class="form-label">
                  Image <span class="text-danger">*</span>
                </label>
                <input
                  class="form-control"
                  type="file"
                  onChange={(e) => this.setState({ image: e.target.files[0] })}
                />
              </div>
            </div>
          </Spin>
        </Modal>

        {/* Edit Service Modal  */}
        <Modal
          title="Update Testimonial"
          visible={this.state.edit_modal_status}
          onOk={() => this.update_testimonial()}
          onCancel={() => this.close_update_modal()}
          okText="Update"
          cancelText="Cancel"
        >
          <Spin spinning={this.state.formloader}>
            <div class="py-4">
              <div class="mb-3">
                <label for="example-text-input" class="form-label">
                  Client Name <span class="text-danger">*</span>
                </label>
                <input
                  class="form-control"
                  type="text"
                  value={this.state.edit_client_name}
                  onChange={(e) =>
                    this.setState({ edit_client_name: e.target.value })
                  }
                />
              </div>
              <div class="mb-3">
                <label for="example-text-input" class="form-label">
                  Company Name <span class="text-danger">*</span>
                </label>
                <input
                  class="form-control"
                  type="text"
                  value={this.state.edit_company_name}
                  onChange={(e) =>
                    this.setState({ edit_company_name: e.target.value })
                  }
                />
              </div>
              <div class="mb-3">
                <label for="example-search-input" class="form-label">
                  Description <span class="text-danger">*</span>
                </label>
                <textarea
                  class="form-control"
                  rows="3"
                  placeholder=""
                  name="detail"
                  id="detail"
                  spellcheck="false"
                  value={this.state.edit_description}
                  onChange={(e) =>
                    this.setState({ edit_description: e.target.value })
                  }
                ></textarea>
              </div>
              <div class="mb-3">
                <label for="example-text-input" class="form-label">
                  User Profile <span class="text-danger">*</span>
                </label>
                {this.state.is_image_present == true && (
                  <div
                    style={{
                      padding: 10,
                      border: "1px solid #ddd",
                      width: "fit-content",
                    }}
                    class="mb-2"
                  >
                    <a href={this.state.image_url} target="_blank">
                      <img src={this.state.image_url} style={{ width: 100 }} />
                    </a>
                  </div>
                )}
                <input
                  class="form-control"
                  type="file"
                  onChange={(e) =>
                    this.setState({ edit_image: e.target.files[0] })
                  }
                />
              </div>
            </div>
          </Spin>
        </Modal>

        <Modal
          title="Delete Testimonial"
          visible={this.state.delete_modal_status}
          onOk={() => this.delete_project()}
          onCancel={() => this.close_delete_modal()}
          okText="Delete"
          cancelText="Cancel"
        >
          <Spin spinning={this.state.formloader}>
            <div class="pt-4">
              <p style={{ fontSize: 16 }}>Are you sure you want to delete?</p>
            </div>
          </Spin>
        </Modal>
      </div>
    );
  }
}
