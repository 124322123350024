import React, { Component } from "react";
import { Spin, message } from "antd";
import axios from "axios";
import URLs from "../../utils/apis";

export default class Hero extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "",
      description: "",
      input_placeholder: "",
      hero_img: "",
      hero_img_url: "",
      is_image_present: "",
      loading: false,
    };
  }
  componentDidMount() {
    this.get_details();
  }
  get_details = () => {
    this.setState({ loading: true });
    axios
      .get(URLs.get_hero_section_settings)
      .then((response) => {
        if (response.data.status == "1") {
          let currentObj = response.data.data;
          let url = URLs.image_url + currentObj.hero_image;
          this.setState({
            title: currentObj.title,
            description: currentObj.description,
            input_placeholder: currentObj.input_placeholder,
            hero_img_url: url,
            is_image_present: currentObj.hero_image ? true : false,
            loading: false,
          });
        } else {
          this.setState({ loading: false });
          return;
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
        console.error("Warning:", error);
        // message.warning("Please try again.");
      });
  };
  save = () => {
    if (!this.state.title) {
      message.warning("Title is required");
      return;
    }
    if (!this.state.description) {
      message.warning("Description is required");
      return;
    }
    if (!this.state.input_placeholder) {
      message.warning("Input placeholder text is required");
      return;
    }
    this.setState({ loading: true });
    let formdata = new FormData();
    formdata.append("title", this.state.title);
    formdata.append("description", this.state.description);
    formdata.append("input_placeholder", this.state.input_placeholder);
    formdata.append("hero_img", this.state.hero_img);
    axios
      .post(URLs.save_hero_section_settings, formdata)
      .then((response) => {
        if (response.data.status == "1") {
          message.success(response.data.message);
          this.setState(
            {
              loading: false,
            },
            () => this.get_details()
          );
        } else {
          message.warning(response.data.message);
          this.setState({ loading: false });
          return;
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
        console.error("Warning:", error);
        // message.warning("Please try again.");
      });
  };
  remove_image = () => {
    axios
      .post(URLs.remove_hero_image)
      .then((response) => {
        if (response.data.status == "1") {
          message.success(response.data.message);
          this.setState(
            {
              loading: false,
            },
            () => this.get_details()
          );
        } else {
          message.warning(response.data.message);
          this.setState({ loading: false });
          return;
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
        console.error("Warning:", error);
        // message.warning("Please try again.");
      });
  };
  render() {
    return (
      <div class="card">
        <div class="card-header">
          <h4 class="card-title" style={{ fontSize: 16, marginBottom: 0 }}>
            Hero Section Settings
          </h4>
        </div>
        <Spin spinning={this.state.loading}>
          <div class="card-body p-4">
            <div class="row">
              <div class="col-lg-12">
                <div>
                  <div class="mb-3">
                    <label for="example-text-input" class="form-label">
                      Title <span class="text-danger">*</span>
                    </label>
                    <input
                      class="form-control"
                      type="text"
                      value={this.state.title}
                      onChange={(e) => this.setState({ title: e.target.value })}
                    />
                  </div>
                  <div class="mb-3">
                    <label for="example-search-input" class="form-label">
                      Description <span class="text-danger">*</span>
                    </label>
                    <textarea
                      class="form-control"
                      rows="3"
                      placeholder=""
                      name="detail"
                      id="detail"
                      spellcheck="false"
                      value={this.state.description}
                      onChange={(e) =>
                        this.setState({ description: e.target.value })
                      }
                    >
                      {this.state.description}
                    </textarea>
                  </div>
                  <div class="mb-3">
                    <label for="example-email-input" class="form-label">
                      Search Placeholder <span class="text-danger">*</span>
                    </label>
                    <input
                      class="form-control"
                      type="text"
                      value="bootstrap@example.com"
                      id="example-email-input"
                      value={this.state.input_placeholder}
                      onChange={(e) =>
                        this.setState({ input_placeholder: e.target.value })
                      }
                    />
                  </div>
                  <div class="mb-3">
                    <label for="example-email-input" class="form-label">
                      Hero Section Image <span class="text-danger">*</span>
                    </label>
                    {this.state.is_image_present == true && (
                      <div
                        style={{
                          padding: 10,
                          border: "1px solid #ddd",
                          width: "fit-content",
                        }}
                        class="mb-2"
                      >
                        <a href={this.state.hero_img_url} target="_blank">
                          <img
                            src={this.state.hero_img_url}
                            style={{ width: 100 }}
                          />
                        </a>
                        <br />
                        <button
                          class="remove-button"
                          onClick={() => this.remove_image()}
                        >
                          <i class="bx bx-x-circle"></i> Remove
                        </button>
                      </div>
                    )}
                    <input
                      class="form-control"
                      type="file"
                      onChange={(e) => {
                        this.setState({ hero_img: e.target.files[0] });
                      }}
                    />
                  </div>
                  <div class="mt-4">
                    <button
                      type="button"
                      onClick={() => this.save()}
                      class="btn btn-primary w-md"
                    >
                      Update
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Spin>
      </div>
    );
  }
}
