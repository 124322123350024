import React, { Component } from "react";
import Header from "./includes/Header";
import Sidebar from "./includes/Sidebar";
import Footer from "./includes/Footer";
import URLs from "./utils/apis";
import axios from "axios";
import { Table, Spin, message } from "antd";
import FullEditor from "ckeditor5-build-full";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import moment from "moment";
import CopyRight from "./includes/CopyRight";

export default class EditPost extends Component {
  constructor(props) {
    super(props);
    this.state = {
      total_users: 0,
      loading: false,

      title: "",
      author_name: "",
      date: "",
      short_description: "",
      long_description: "",
      banner_img: "",
      post_id: "",
      banner_img_url: "",
    };
  }
  componentDidMount() {
    const urlParams = new URLSearchParams(window.location.search);
    const secret = urlParams.get("secret");
    this.setState({ post_id: secret }, () => this.get_data());
  }
  get_data = () => {
    axios
      .get(URLs.get_blog_post_list)
      .then((response) => {
        if (response.data.status == "1") {
          for (let item of response.data.data) {
            if (item.post_id == this.state.post_id) {
              let banner_url = URLs.image_url + item.banner_img;
              this.setState({
                title: item.title,
                author_name: item.author,
                date: item.date,
                short_description: item.short_description,
                long_description: JSON.parse(item.description),
                banner_img_url: banner_url,
              });
              return;
            }
          }
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
        console.error("Warning:", error);
      });
  };
  update_post = () => {
    if (!this.state.title) {
      message.warning("Title is required.");
      return;
    }
    if (!this.state.author_name) {
      message.warning("Author name is required.");
      return;
    }
    if (!this.state.date) {
      message.warning("Date is required.");
      return;
    }
    // if (!this.state.short_description) {
    //   message.warning("Short description is required.");
    //   return;
    // }
    if (!this.state.long_description) {
      message.warning("Description is required.");
      return;
    }
    this.setState({ loading: true });
    let formData = new FormData();
    formData.append("title", this.state.title);
    formData.append("author_name", this.state.author_name);
    formData.append("date", moment(this.state.date).format("YYYY-MM-DD"));
    formData.append("short_description", this.state.short_description);
    formData.append(
      "long_description",
      JSON.stringify(this.state.long_description)
    );
    formData.append("banner_img", this.state.banner_img);
    formData.append("post_id", this.state.post_id);
    axios
      .post(URLs.update_blog_post, formData)
      .then((response) => {
        if (response.data.status == "1") {
          message.success(response.data.message);
          this.setState(
            {
              loading: false,
            },
            () => {
              setTimeout(() => {
                window.location.assign("/blog-posts");
              }, 2000);
            }
          );
        } else {
          message.warning(response.data.message);
          this.setState({ loading: false });
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
        console.error("Warning:", error);
      });
  };
  render() {
    return (
      <div id="layout-wrapper">
        <Header />
        <Sidebar />
        <div class="main-content">
          <div class="page-content">
            <div class="container-fluid">
              <div class="row">
                <div class="col-12">
                  <a
                    href="/blog-posts"
                    class="btn btn-dark mb-3"
                    style={{
                      width: 100,
                      marginLeft: "auto",
                      marginRight: 20,
                    }}
                  >
                    Back
                  </a>
                  <div class="page-title-box d-sm-flex align-items-center justify-content-between">
                    <h4 class="mb-sm-0 font-size-18">Edit Post</h4>
                    <div class="page-title-right"></div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-12">
                  <div class="card">
                    <Spin spinning={this.state.loading}>
                      <div class="card-header row px-0">
                        <div className="col-lg-8">
                          <div className="mb-4 form-group">
                            <label>
                              Title <span className="text-danger">*</span>
                            </label>
                            <input
                              className="form-control"
                              placeholder="Title"
                              value={this.state.title}
                              onChange={(e) =>
                                this.setState({ title: e.target.value })
                              }
                            />
                          </div>
                          <div className="mb-4 form-group">
                            <label>
                              Author Name <span className="text-danger">*</span>
                            </label>
                            <input
                              className="form-control"
                              placeholder="Author Name"
                              value={this.state.author_name}
                              onChange={(e) =>
                                this.setState({ author_name: e.target.value })
                              }
                            />
                          </div>
                          <div className="mb-4 form-group">
                            <label>
                              Date <span className="text-danger">*</span>
                            </label>
                            <input
                              type="date"
                              className="form-control"
                              value={this.state.date}
                              onChange={(e) =>
                                this.setState({ date: e.target.value })
                              }
                            />
                          </div>
                          {/* <div className="mb-4 form-group">
                            <label>
                              Short Description{" "}
                              <span className="text-danger">*</span>
                            </label>
                            <textarea
                              className="form-control"
                              name="txtname"
                              rows="4"
                              cols="50"
                              value={this.state.short_description}
                              onChange={(e) =>
                                this.setState({
                                  short_description: e.target.value,
                                })
                              }
                            ></textarea>
                          </div> */}
                          <div className="mb-4 form-group">
                            <label>
                              Description <span className="text-danger">*</span>
                            </label>
                            <CKEditor
                              data={this.state.long_description}
                              editor={FullEditor}
                              onChange={(event, editor) => {
                                const data = editor.getData();
                                this.setState({ long_description: data });
                              }}
                            />
                          </div>
                          <div className="mb-4 form-group">
                            <label>
                              Banner <span className="text-danger">*</span>
                            </label>

                            <div
                              style={{
                                padding: 10,
                                border: "1px solid #ddd",
                                width: "fit-content",
                              }}
                              class="mb-2"
                            >
                              <a
                                href={this.state.banner_img_url}
                                target="_blank"
                              >
                                <img
                                  src={this.state.banner_img_url}
                                  style={{ width: 100 }}
                                />
                              </a>
                            </div>
                            <input
                              type="file"
                              className="form-control"
                              onChange={(e) =>
                                this.setState({ banner_img: e.target.files[0] })
                              }
                            />
                          </div>
                        </div>
                        <div className="col-lg-4"></div>
                        <div className="col-lg-4">
                          <a
                            href="/blog-posts"
                            class="btn btn-dark mb-3"
                            style={{
                              width: 100,
                              marginLeft: "auto",
                              marginRight: 20,
                            }}
                          >
                            Back
                          </a>
                          <button
                            type="button"
                            class="btn btn-primary mb-3"
                            style={{
                              width: 130,
                              marginLeft: "auto",
                            }}
                            onClick={() => this.update_post()}
                          >
                            Update Post
                          </button>
                        </div>
                      </div>
                    </Spin>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <CopyRight />
        </div>
      </div>
    );
  }
}
