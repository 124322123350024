import React, { Component } from "react";
import { Spin, message, Table, Modal } from "antd";
import axios from "axios";
import URLs from "../../utils/apis";

export default class Whoweare extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      listLoader: false,
      records: [],

      add_modal_status: false,
      title: "",
      description: "",
      icon_img: "",
      formloader: false,

      who_id: 0,
      edit_modal_status: false,
      edit_title: "",
      edit_description: "",
      edit_icon_img: "",

      who_image_url: "",
      is_image_present: false,

      delete_modal_status: false,
    };
  }
  componentDidMount() {
    this.get_records();
  }
  get_records = () => {
    this.setState({ listLoader: true });
    axios
      .get(URLs.get_who_we_list)
      .then((response) => {
        if (response.data.status == "1") {
          let list = response.data.data;
          this.setState({
            records: list,
            listLoader: false,
          });
        } else {
          this.setState({ listLoader: false });
          return;
        }
      })
      .catch((error) => {
        this.setState({ listLoader: false });
      });
  };
  save_who_we_are = () => {
    if (!this.state.title) {
      message.warning("Title is required");
      return;
    }
    if (!this.state.description) {
      message.warning("Description is required");
      return;
    }
    if (!this.state.icon_img) {
      message.warning("Please select icon");
      return;
    }
    this.setState({ formloader: true });
    let formData = new FormData();
    formData.append("title", this.state.title);
    formData.append("description", this.state.description);
    formData.append("icon_img", this.state.icon_img);
    axios
      .post(URLs.save_who_we_are, formData)
      .then((response) => {
        if (response.data.status == "1") {
          message.success(response.data.message);
          this.setState(
            {
              formloader: false,
              service_name: "",
              service_description: "",
              add_modal_status: false,
              service_image: "",
            },
            () => this.get_records()
          );
        } else {
          message.warning(response.data.message);
          this.setState({ formloader: false });
          return;
        }
      })
      .catch((error) => {
        this.setState({ formloader: false });
        console.error("Warning:", error);
        // message.warning("Please try again.");
      });
  };
  open_add_modal = () => {
    this.setState({
      add_modal_status: true,
      title: "",
      description: "",
      icon_img: "",
    });
  };
  close_save_modal = () => {
    this.setState({
      add_modal_status: false,
      title: "",
      description: "",
      icon_img: "",
    });
  };
  open_edit_modal = (item) => {
    let url = URLs.image_url + item.icon_img;
    this.setState({
      edit_modal_status: true,
      edit_title: item.title,
      edit_description: item.description,
      who_image_url: url,
      who_id: item.id,
      is_image_present: item.icon_img ? true : false,
    });
  };
  close_update_modal = () => {
    this.setState({
      edit_modal_status: false,
      edit_title: "",
      edit_description: "",
      who_image_url: "",
      who_id: "",
      is_image_present: false,
    });
  };
  update_service = () => {
    if (!this.state.edit_title) {
      message.warning("Title is required");
      return;
    }
    if (!this.state.edit_description) {
      message.warning("Description is required");
      return;
    }
    this.setState({ formloader: true });
    let formData = new FormData();
    formData.append("title", this.state.edit_title);
    formData.append("description", this.state.edit_description);
    formData.append("icon_img", this.state.edit_icon_img);
    formData.append("who_id", this.state.who_id);
    axios
      .post(URLs.update_who_we_are, formData)
      .then((response) => {
        if (response.data.status == "1") {
          message.success(response.data.message);
          this.setState(
            {
              formloader: false,
              edit_title: "",
              edit_description: "",
              edit_icon_img: "",
              edit_modal_status: false,
              service_image: "",
              who_id: 0,
            },
            () => this.get_records()
          );
        } else {
          message.warning(response.data.message);
          this.setState({ formloader: false });
          return;
        }
      })
      .catch((error) => {
        this.setState({ formloader: false });
        console.error("Warning:", error);
      });
  };
  close_delete_modal = () => {
    this.setState({
      delete_modal_status: false,
      who_id: "",
    });
  };
  open_delete_modal = (item) => {
    this.setState({
      delete_modal_status: true,
      who_id: item.id,
    });
  };
  delete_service = () => {
    let params = {
      who_id: this.state.who_id,
    };
    axios
      .post(URLs.delete_who_we_are, params)
      .then((response) => {
        if (response.data.status == "1") {
          message.success(response.data.message);
          this.setState(
            {
              formloader: false,
              delete_modal_status: false,
              who_id: 0,
            },
            () => this.get_records()
          );
        } else {
          message.warning(response.data.message);
          this.setState({ formloader: false });
          return;
        }
      })
      .catch((error) => {
        this.setState({ formloader: false });
        console.error("Warning:", error);
      });
  };
  render() {
    const columns = [
      {
        title: "Title",
        dataIndex: "title",
        key: "title",
        width: 150,
      },
      {
        title: "Description",
        dataIndex: "desc",
        key: "desc",
      },
      {
        title: "Action",
        dataIndex: "action",
        key: "action",
        render: (item) => {
          return (
            <div>
              <button
                type="button"
                onClick={() => this.open_edit_modal(item)}
                class="btn btn-primary mb-3"
                style={{ width: 50, height: 35 }}
              >
                <box-icon
                  name="edit-alt"
                  color="#ffffff"
                  size={"18px"}
                ></box-icon>
              </button>
              <button
                type="button"
                onClick={() => this.open_delete_modal(item)}
                class="btn btn-danger mb-3"
                style={{ width: 50, height: 35 }}
              >
                <box-icon name="trash" color="white" size={"18px"}></box-icon>
              </button>
            </div>
          );
        },
      },
    ];
    const dataSource =
      this.state.records &&
      this.state.records.map((item, index) => {
        return {
          title: item.title ? item.title : "---",
          desc: item.description ? item.description : "---",
          action: item,
        };
      });
    return (
      <div class="card">
        <div class="card-header">
          <h4 class="card-title" style={{ fontSize: 16, marginBottom: 0 }}>
            Who We Are
          </h4>
        </div>
        <Spin spinning={this.state.loading}>
          <div class="card-body p-4">
            <div class="row">
              <div class="col-lg-12">
                <div class="card mt-3 p-3">
                  <button
                    type="button"
                    onClick={() => this.open_add_modal()}
                    class="btn btn-primary mb-3"
                    style={{ width: 70, float: "right", marginLeft: "auto" }}
                  >
                    Add
                  </button>
                  <Table
                    bordered
                    loading={this.state.listLoader}
                    dataSource={dataSource}
                    columns={columns}
                  />
                </div>
              </div>
            </div>
          </div>
        </Spin>

        {/* Add Service Modal  */}
        <Modal
          title="Add Who We Are Content"
          visible={this.state.add_modal_status}
          onOk={() => this.save_who_we_are()}
          onCancel={() => this.close_save_modal()}
          okText="Save"
          cancelText="Cancel"
        >
          <Spin spinning={this.state.formloader}>
            <div class="py-4">
              <div class="mb-3">
                <label for="example-text-input" class="form-label">
                  Title <span class="text-danger">*</span>
                </label>
                <input
                  class="form-control"
                  type="text"
                  value={this.state.title}
                  onChange={(e) => this.setState({ title: e.target.value })}
                />
              </div>
              <div class="mb-3">
                <label for="example-search-input" class="form-label">
                  Description <span class="text-danger">*</span>
                </label>
                <textarea
                  class="form-control"
                  rows="3"
                  placeholder=""
                  name="detail"
                  id="detail"
                  spellcheck="false"
                  value={this.state.description}
                  onChange={(e) =>
                    this.setState({ description: e.target.value })
                  }
                ></textarea>
              </div>
              <div class="mb-3">
                <label for="example-text-input" class="form-label">
                  Icon <span class="text-danger">*</span>
                </label>
                <input
                  class="form-control"
                  type="file"
                  onChange={(e) =>
                    this.setState({ icon_img: e.target.files[0] })
                  }
                />
              </div>
            </div>
          </Spin>
        </Modal>

        {/* Edit Service Modal  */}
        <Modal
          title="Update Who We Are Content"
          visible={this.state.edit_modal_status}
          onOk={() => this.update_service()}
          onCancel={() => this.close_update_modal()}
          okText="Update"
          cancelText="Cancel"
        >
          <Spin spinning={this.state.formloader}>
            <div class="py-4">
              <div class="mb-3">
                <label for="example-text-input" class="form-label">
                  Title <span class="text-danger">*</span>
                </label>
                <input
                  class="form-control"
                  type="text"
                  value={this.state.edit_title}
                  onChange={(e) =>
                    this.setState({ edit_title: e.target.value })
                  }
                />
              </div>
              <div class="mb-3">
                <label for="example-search-input" class="form-label">
                  Description <span class="text-danger">*</span>
                </label>
                <textarea
                  class="form-control"
                  rows="3"
                  placeholder=""
                  name="detail"
                  id="detail"
                  spellcheck="false"
                  value={this.state.edit_description}
                  onChange={(e) =>
                    this.setState({ edit_description: e.target.value })
                  }
                ></textarea>
              </div>
              <div class="mb-3">
                <label for="example-text-input" class="form-label">
                  Icon <span class="text-danger">*</span>
                </label>
                {this.state.is_image_present == true && (
                  <div
                    style={{
                      padding: 10,
                      border: "1px solid #ddd",
                      width: "fit-content",
                    }}
                    class="mb-2"
                  >
                    <a href={this.state.who_image_url} target="_blank">
                      <img
                        src={this.state.who_image_url}
                        style={{ width: 100 }}
                      />
                    </a>
                  </div>
                )}
                <input
                  class="form-control"
                  type="file"
                  onChange={(e) =>
                    this.setState({ edit_icon_img: e.target.files[0] })
                  }
                />
              </div>
            </div>
          </Spin>
        </Modal>

        <Modal
          title="Delete Record"
          visible={this.state.delete_modal_status}
          onOk={() => this.delete_service()}
          onCancel={() => this.close_delete_modal()}
          okText="Delete"
          cancelText="Cancel"
        >
          <Spin spinning={this.state.formloader}>
            <div class="pt-4">
              <p style={{ fontSize: 16 }}>Are you sure you want to delete?</p>
            </div>
          </Spin>
        </Modal>
      </div>
    );
  }
}
