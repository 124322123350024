import React, { Component } from "react";
import Header from "./includes/Header";
import Sidebar from "./includes/Sidebar";
import Footer from "./includes/Footer";
import URLs from "./utils/apis";
import axios from "axios";
import { Table, Modal, Spin, message } from "antd";
import CopyRight from "./includes/CopyRight";

export default class MetaSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      total_users: 0,
      records: [],
      loading: false,
      title: "",
      description: "",
      keywords: "",
      page: "",
      meta_id: "",
      modal_status: false,
      formloader: false,
    };
  }
  componentDidMount() {
    this.get_data();
  }
  get_data = () => {
    this.setState({ loading: true });
    axios
      .get(URLs.get_meta_settings)
      .then((response) => {
        if (response.data.status == "1") {
          this.setState({
            records: response.data.data,
            loading: false,
          });
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
        console.error("Warning:", error);
      });
  };
  openModal = (item) => {
    this.setState({
      title: item.title,
      description: item.description,
      keywords: item.keywords,
      page: item.page,
      meta_id: item.meta_id,
      modal_status: true,
    });
  };
  update_settings = () => {
    this.setState({ loading: true });
    let params = {
      title: this.state.title,
      description: this.state.description,
      keywords: this.state.keywords,
      meta_id: this.state.meta_id,
    };
    axios
      .post(URLs.save_meta_setting, params)
      .then((response) => {
        if (response.data.status == "1") {
          message.success(response.data.message);
          this.setState(
            {
              loading: false,
              modal_status: false,
              title: "",
              description: "",
              keywords: "",
              page: "",
              meta_id: "",
            },
            () => this.get_data()
          );
        } else {
          message.warning(response.data.message);
          this.setState({ loading: false });
          return;
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
        console.error("Warning:", error);
        // message.warning("Please try again.");
      });
  };
  close_modal = () => {
    this.setState({
      title: "",
      description: "",
      keywords: "",
      page: "",
      meta_id: "",
      modal_status: false,
    });
  };
  render() {
    const dataSource =
      this.state.records &&
      this.state.records.map((item, index) => {
        return {
          pname: item.page,
          title: item.title,
          description: item.description,
          keywords: item.keywords,
          action: item,
        };
      });
    const columns = [
      {
        title: "Page Name",
        dataIndex: "pname",
        key: "pname",
      },
      {
        title: "Title",
        dataIndex: "title",
        key: "title",
      },
      {
        title: "Description",
        dataIndex: "description",
        key: "description",
      },
      {
        title: "Keywords",
        dataIndex: "keywords",
        key: "keywords",
      },
      {
        title: "Action",
        dataIndex: "action",
        key: "action",
        render: (item) => {
          return (
            <div>
              <button
                type={`button`}
                class="btn btn-primary mb-3"
                style={{
                  width: 100,
                  marginLeft: "auto",
                  marginRight: 20,
                }}
                onClick={() => this.openModal(item)}
              >
                Edit
              </button>
            </div>
          );
        },
      },
    ];
    return (
      <div id="layout-wrapper">
        <Header />
        <Sidebar />
        <div class="main-content">
          <div class="page-content">
            <div class="container-fluid">
              <div class="row">
                <div class="col-12">
                  <div class="page-title-box d-sm-flex align-items-center justify-content-between">
                    <h4 class="mb-sm-0 font-size-18">Meta Settings</h4>
                    <div class="page-title-right"></div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-12">
                  <div class="card">
                    <div class="card-body">
                      <div class="row">
                        <div class="col-lg-12">
                          <Table
                            bordered
                            dataSource={dataSource}
                            columns={columns}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <CopyRight />

          <Modal
            title="Update Setting"
            visible={this.state.modal_status}
            onOk={() => this.update_settings()}
            onCancel={() => this.close_modal()}
            okText="Update"
            cancelText="Cancel"
          >
            <Spin spinning={this.state.formloader}>
              <div class="py-4">
                <div class="mb-3">
                  <label for="example-text-input" class="form-label">
                    Title
                  </label>
                  <input
                    class="form-control"
                    type="text"
                    value={this.state.title}
                    onChange={(e) => this.setState({ title: e.target.value })}
                  />
                </div>
                <div class="mb-3">
                  <label for="example-text-input" class="form-label">
                    Description
                  </label>
                  <textarea
                    class="form-control"
                    id="txtid"
                    name="txtname"
                    value={this.state.description}
                    onChange={(e) =>
                      this.setState({ description: e.target.value })
                    }
                    rows="4"
                    cols="50"
                  ></textarea>
                </div>
                <div class="mb-3">
                  <label for="example-text-input" class="form-label">
                    Keywords
                  </label>
                  <textarea
                    class="form-control"
                    id="txtid"
                    name="txtname"
                    value={this.state.keywords}
                    onChange={(e) =>
                      this.setState({ keywords: e.target.value })
                    }
                    rows="4"
                    cols="50"
                  ></textarea>
                </div>
              </div>
            </Spin>
          </Modal>
        </div>
      </div>
    );
  }
}
