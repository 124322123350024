import React, { Component } from "react";
import Header from "./includes/Header";
import Sidebar from "./includes/Sidebar";
import Footer from "./includes/Footer";
import URLs from "./utils/apis";
import axios from "axios";
import { Table, Spin, Modal, message } from "antd";
import CopyRight from "./includes/CopyRight";

export default class BlogPosts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      total_users: 0,
      records: [],
      crecords: [],
      loading: false,
      delete_modal_status: false,
      formloader: false,
      search_text: "",
    };
  }
  componentDidMount() {
    this.get_post_list();
  }
  get_post_list = () => {
    this.setState({ loading: true });
    axios
      .get(URLs.get_blog_post_list)
      .then((response) => {
        if (response.data.status == "1") {
          this.setState({
            records: response.data.data,
            crecords: response.data.data,
            loading: false,
          });
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
        console.error("Warning:", error);
      });
  };
  close_delete_modal = () => {
    this.setState({
      delete_modal_status: false,
      post_id: "",
    });
  };
  open_delete_modal = (item) => {
    this.setState({
      delete_modal_status: true,
      post_id: item.post_id,
    });
  };
  delete_post = () => {
    let params = {
      post_id: this.state.post_id,
    };
    axios
      .post(URLs.delete_blog_post, params)
      .then((response) => {
        if (response.data.status == "1") {
          message.success(response.data.message);
          this.setState(
            {
              formloader: false,
              delete_modal_status: false,
              post_id: 0,
            },
            () => this.get_post_list()
          );
        } else {
          message.warning(response.data.message);
          this.setState({ formloader: false });
          return;
        }
      })
      .catch((error) => {
        this.setState({ formloader: false });
        console.error("Warning:", error);
      });
  };
  search = () => {
    let text = this.state.search_text;
    let tempArr = [];
    let list = this.state.crecords;
    for (let item of list) {
      if (
        (item.title && item.title.toLowerCase().includes(text.toLowerCase())) ||
        (item.author &&
          item.author.toLowerCase().includes(text.toLowerCase())) ||
        (item.date && item.date == text)
      ) {
        tempArr = [...tempArr, item];
      }
    }
    this.setState({ records: tempArr });
  };
  render() {
    const dataSource =
      this.state.records &&
      this.state.records.map((item, index) => {
        return {
          title: item.title,
          author: item.author,
          date: item.date,
          action: item,
        };
      });
    const columns = [
      {
        title: "Title",
        dataIndex: "title",
        key: "title",
      },
      {
        title: "Author Name",
        dataIndex: "author",
        key: "author",
      },
      {
        title: "Date",
        dataIndex: "date",
        key: "date",
      },
      {
        title: "Action",
        dataIndex: "action",
        key: "action",
        render: (item) => {
          return (
            <div>
              <a
                href={`/edit-post?secret=${item.post_id}`}
                class="btn btn-primary mb-3"
                style={{
                  width: 100,
                  marginLeft: "auto",
                  marginRight: 20,
                }}
              >
                Edit
              </a>
              <button
                type={`button`}
                onClick={() => this.open_delete_modal(item)}
                class="btn btn-danger mb-3"
                style={{
                  width: 100,
                  marginLeft: "auto",
                  marginRight: 20,
                }}
              >
                Delete
              </button>
            </div>
          );
        },
      },
    ];
    return (
      <div id="layout-wrapper">
        <Header />
        <Sidebar />
        <div class="main-content">
          <div class="page-content">
            <div class="container-fluid">
              <div class="row">
                <div class="col-12">
                  <div class="page-title-box d-sm-flex align-items-center justify-content-between">
                    <h4 class="mb-sm-0 font-size-18">
                      Blog Posts - (Total: {this.state.records.length})
                    </h4>
                    <div class="page-title-right"></div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-12">
                  <div class="card">
                    <div class="card-header row px-0">
                      <div className="col-lg-4">
                        <input
                          className="form-control"
                          placeholder="Search.."
                          value={this.state.search_text}
                          onChange={(e) =>
                            this.setState({ search_text: e.target.value }, () =>
                              this.search()
                            )
                          }
                        />
                      </div>
                      <div className="col-lg-4"></div>
                      <div className="col-lg-4">
                        <a
                          href="/add-new-post"
                          class="btn btn-primary mb-3"
                          style={{
                            width: 150,
                            float: "right",
                            marginLeft: "auto",
                          }}
                        >
                          Add New Post
                        </a>
                      </div>
                    </div>
                    <div class="card-body">
                      <Table
                        bordered
                        dataSource={dataSource}
                        columns={columns}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <CopyRight />

          <Modal
            title="Delete Post"
            visible={this.state.delete_modal_status}
            onOk={() => this.delete_post()}
            onCancel={() => this.close_delete_modal()}
            okText="Delete"
            cancelText="Cancel"
          >
            <Spin spinning={this.state.formloader}>
              <div class="pt-4">
                <p style={{ fontSize: 16 }}>Are you sure you want to delete?</p>
              </div>
            </Spin>
          </Modal>
        </div>
      </div>
    );
  }
}
