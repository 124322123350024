import React, { Component } from "react";
import { Spin, message, Table, Modal } from "antd";
import axios from "axios";
import URLs from "../../utils/apis";
import { TagsInput } from "react-tag-input-component";

export default class MeetOurTeam extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "",
      description: "",
      button_link: "",
      button_text: "",
      loading: false,
      listLoader: false,
      project_list: [],

      add_modal_status: false,
      client_name: "",
      info_data: [
        {
          id: 1,
          title: "",
          desc: "",
        },
      ],
      project_name: "",
      project_description: "",
      project_image: "",
      problem_statement: [
        {
          id: 1,
          title: "",
        },
      ],
      solution_results: [
        {
          id: 1,
          title: "",
        },
      ],
      formloader: false,

      team_id: 0,
      edit_modal_status: false,
      edit_image: "",
      edit_client_name: "",
      edit_info_data: [
        {
          id: 1,
          title: "",
          desc: "",
        },
      ],
      edit_project_name: "",
      edit_project_description: "",
      edit_project_image: "",
      edit_problem_statement: [{ id: 1, title: "" }],
      edit_solution_results: [{ id: 1, title: "" }],
      team_image_url: "",
      is_image_present: false,

      delete_modal_status: false,
    };
  }
  componentDidMount() {
    this.get_details();
    this.get_project_list();
  }
  get_details = () => {
    this.setState({ loading: true });
    axios
      .get(URLs.get_team_settings)
      .then((response) => {
        if (response.data.status == "1") {
          let currentObj = response.data.data;
          this.setState({
            title: currentObj.title,
            description: currentObj.description,
            button_link: currentObj.button_link,
            button_text: currentObj.button_text,
            loading: false,
          });
        } else {
          this.setState({ loading: false });
          return;
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
        console.error("Warning:", error);
        message.warning("Please try again.");
      });
  };
  save_settings = () => {
    if (!this.state.title) {
      message.warning("Title is required");
      return;
    }
    if (!this.state.description) {
      message.warning("Description is required");
      return;
    }
    if (!this.state.button_text) {
      message.warning("Button text is required");
      return;
    }
    if (!this.state.button_link) {
      message.warning("Button link is required");
      return;
    }
    this.setState({ loading: true });
    let params = {
      title: this.state.title,
      description: this.state.description,
      button_text: this.state.button_text,
      button_link: this.state.button_link,
    };
    axios
      .post(URLs.save_team_settings, params)
      .then((response) => {
        if (response.data.status == "1") {
          message.success(response.data.message);
          this.setState(
            {
              loading: false,
            },
            () => this.get_details()
          );
        } else {
          message.warning(response.data.message);
          this.setState({ loading: false });
          return;
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
        console.error("Warning:", error);
        message.warning("Please try again.");
      });
  };
  get_project_list = () => {
    this.setState({ listLoader: true });
    axios
      .get(URLs.get_team_list)
      .then((response) => {
        if (response.data.status == "1") {
          let currentObj = response.data.data;
          this.setState({
            project_list: response.data.data,
            listLoader: false,
          });
        } else {
          this.setState({ listLoader: false });
          return;
        }
      })
      .catch((error) => {
        this.setState({ listLoader: false });
      });
  };
  save_project = () => {
    if (!this.state.client_name) {
      message.warning("Client name is required");
      return;
    }
    if (!this.state.project_name) {
      message.warning("Project name is required");
      return;
    }
    if (!this.state.project_description) {
      message.warning("Project description is required");
      return;
    }
    if (!this.state.project_image) {
      message.warning("Please select project image");
      return;
    }
    if (this.state.problem_statement.length == 0) {
      message.warning("Please add problem statement for project");
      return;
    }
    if (this.state.problem_statement.length > 0) {
      let list = this.state.problem_statement;
      for (let item of list) {
        if (!item.title) {
          message.warning("Please enter the title of problem statement");
          return;
        }
      }
    }
    if (this.state.solution_results.length == 0) {
      message.warning("Please add solutions & results for project");
      return;
    }
    if (this.state.solution_results.length > 0) {
      let list = this.state.solution_results;
      for (let item of list) {
        if (!item.title) {
          message.warning("Please enter the title of Solutions & Results");
          return;
        }
      }
    }
    if (this.state.info_data.length == 0) {
      message.warning("Please add info data for projects");
      return;
    }
    if (this.state.info_data.length > 0) {
      let list = this.state.info_data;
      for (let item of list) {
        if (!item.title) {
          message.warning("Please enter the title of Info List");
          return;
        }
        if (!item.desc) {
          message.warning("Please enter the Description of Info List");
          return;
        }
      }
    }
    this.setState({ formloader: true });
    let formData = new FormData();
    formData.append("client_name", this.state.client_name);
    formData.append("project_name", this.state.project_name);
    formData.append("description", this.state.project_description);
    formData.append("project_image", this.state.project_image);
    formData.append("info_data", JSON.stringify(this.state.info_data));
    formData.append(
      "problem_statement",
      JSON.stringify(this.state.problem_statement)
    );
    formData.append(
      "solution_results",
      JSON.stringify(this.state.solution_results)
    );
    axios
      .post(URLs.save_project, formData)
      .then((response) => {
        if (response.data.status == "1") {
          message.success(response.data.message);
          this.setState(
            {
              formloader: false,
              add_modal_status: false,
              client_name: "",
              info_data: [
                {
                  id: 1,
                  title: "",
                  desc: "",
                },
              ],
              project_name: "",
              project_description: "",
              project_image: "",
              problem_statement: [
                {
                  id: 1,
                  title: "",
                },
              ],
              solution_results: [
                {
                  id: 1,
                  title: "",
                },
              ],
            },
            () => this.get_project_list()
          );
        } else {
          message.warning(response.data.message);
          this.setState({ formloader: false });
          return;
        }
      })
      .catch((error) => {
        this.setState({ formloader: false });
        console.error("Warning:", error);
        message.warning("Please try again.");
      });
  };
  open_add_modal = () => {
    this.setState({
      add_modal_status: true,
      client_name: "",
      info_data: [
        {
          id: 1,
          title: "",
          desc: "",
        },
      ],
      project_name: "",
      project_description: "",
      project_image: "",
      problem_statement: [
        {
          id: 1,
          title: "",
        },
      ],
      solution_results: [
        {
          id: 1,
          title: "",
        },
      ],
    });
  };
  close_save_modal = () => {
    this.setState({
      add_modal_status: false,
      client_name: "",
      info_data: [
        {
          id: 1,
          title: "",
          desc: "",
        },
      ],
      project_name: "",
      project_description: "",
      project_image: "",
      problem_statement: [],
      solution_results: [],
    });
  };
  open_edit_modal = (item) => {
    let banner_url = URLs.image_url + item.image;
    this.setState({
      edit_modal_status: true,
      edit_name: item.name,
      team_image_url: banner_url,
      team_id: item.team_id,
      is_image_present: item.imag ? true : false,
    });
  };
  close_update_modal = () => {
    this.setState({
      is_image_present: false,
      team_id: "",
      edit_modal_status: false,
      client_name: "",
      info_data: [
        {
          id: 1,
          title: "",
          desc: "",
        },
      ],
      project_name: "",
      project_description: "",
      project_image: "",
      problem_statement: [
        {
          id: 1,
          title: "",
        },
      ],
      solution_results: [
        {
          id: 1,
          title: "",
        },
      ],
    });
  };
  close_save_modal = () => {
    this.setState({
      add_modal_status: false,
      client_name: "",
      info_data: [
        {
          id: 1,
          title: "",
          desc: "",
        },
      ],
      project_name: "",
      project_description: "",
      project_image: "",
      problem_statement: [
        {
          id: 1,
          title: "",
        },
      ],
      solution_results: [
        {
          id: 1,
          title: "",
        },
      ],
    });
  };

  update_team = () => {
    this.setState({ formloader: true });
    let formData = new FormData();
    formData.append("name", this.state.edit_name);
    formData.append("image", this.state.edit_image);
    formData.append("team_id", this.state.team_id);
    axios
      .post(URLs.update_team, formData)
      .then((response) => {
        if (response.data.status == "1") {
          message.success(response.data.message);
          this.setState(
            {
              formloader: false,
              edit_modal_status: false,
              edit_name: "",
              edit_image: "",
              team_id: 0,
            },
            () => this.get_project_list()
          );
        } else {
          message.warning(response.data.message);
          this.setState({ formloader: false });
          return;
        }
      })
      .catch((error) => {
        this.setState({ formloader: false });
        console.error("Warning:", error);
      });
  };
  close_delete_modal = () => {
    this.setState({
      delete_modal_status: false,
      team_id: "",
    });
  };
  open_delete_modal = (item) => {
    this.setState({
      delete_modal_status: true,
      team_id: item.team_id,
    });
  };
  delete_project = () => {
    let params = {
      team_id: this.state.team_id,
    };
    axios
      .post(URLs.delete_project, params)
      .then((response) => {
        if (response.data.status == "1") {
          message.success(response.data.message);
          this.setState(
            {
              formloader: false,
              delete_modal_status: false,
              team_id: 0,
            },
            () => this.get_project_list()
          );
        } else {
          message.warning(response.data.message);
          this.setState({ formloader: false });
          return;
        }
      })
      .catch((error) => {
        this.setState({ formloader: false });
        console.error("Warning:", error);
      });
  };

  render() {
    const columns = [
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
        width: 150,
      },
      {
        title: "Team Image",
        dataIndex: "profile",
        key: "profile",
        render: (item) => {
          let img_url = URLs.image_url + item.image;
          return (
            <div>
              <img src={img_url} style={{ maxWidth: 85 }} />
            </div>
          );
        },
      },
      {
        title: "Action",
        dataIndex: "action",
        key: "action",
        render: (item) => {
          return (
            <div>
              <button
                type="button"
                onClick={() => this.open_edit_modal(item)}
                class="btn btn-primary mb-3"
                style={{ width: 50, height: 35, marginRight: 10 }}
              >
                <box-icon
                  name="edit-alt"
                  color="#ffffff"
                  size={"18px"}
                ></box-icon>
              </button>
            </div>
          );
        },
      },
    ];
    const dataSource = this.state.project_list.map((item, index) => {
      return {
        name: item.name ? item.name : "---",
        profile: item,
        action: item,
      };
    });
    return (
      <div class="card">
        <div class="card-header">
          <h4 class="card-title" style={{ fontSize: 16, marginBottom: 0 }}>
            Meet Our Team
          </h4>
        </div>
        <Spin spinning={this.state.loading}>
          <div class="card-body p-4">
            <div class="row">
              <div class="col-lg-12">
                <div>
                  <div class="mb-3">
                    <label for="example-text-input" class="form-label">
                      Title
                    </label>
                    <input
                      class="form-control"
                      type="text"
                      value={this.state.title}
                      onChange={(e) => this.setState({ title: e.target.value })}
                    />
                  </div>
                  <div class="mb-3">
                    <label for="example-search-input" class="form-label">
                      Description
                    </label>
                    <textarea
                      class="form-control"
                      rows="3"
                      placeholder=""
                      name="detail"
                      id="detail"
                      spellcheck="false"
                      value={this.state.description}
                      onChange={(e) =>
                        this.setState({ description: e.target.value })
                      }
                    >
                      {this.state.description}
                    </textarea>
                  </div>
                  <div class="mb-3">
                    <label for="example-text-input" class="form-label">
                      Button Text
                    </label>
                    <input
                      class="form-control"
                      type="text"
                      value={this.state.button_text}
                      onChange={(e) =>
                        this.setState({ button_text: e.target.value })
                      }
                    />
                  </div>
                  <div class="mb-3">
                    <label for="example-search-input" class="form-label">
                      Button Link
                    </label>
                    <textarea
                      class="form-control"
                      rows="3"
                      placeholder=""
                      name="detail"
                      id="detail"
                      spellcheck="false"
                      value={this.state.button_link}
                      onChange={(e) =>
                        this.setState({ button_link: e.target.value })
                      }
                    >
                      {this.state.button_link}
                    </textarea>
                  </div>
                  <div class="mt-4">
                    <button
                      type="button"
                      onClick={() => this.save_settings()}
                      class="btn btn-primary w-md"
                    >
                      Update
                    </button>
                  </div>
                </div>
                <div class="card mt-3 p-3">
                  <Table
                    bordered
                    loading={this.state.listLoader}
                    dataSource={dataSource}
                    columns={columns}
                  />
                </div>
              </div>
            </div>
          </div>
        </Spin>

        {/* Edit Service Modal  */}
        <Modal
          title="Update Team"
          visible={this.state.edit_modal_status}
          onOk={() => this.update_team()}
          onCancel={() => this.close_update_modal()}
          okText="Update"
          cancelText="Cancel"
        >
          <Spin spinning={this.state.formloader}>
            <div class="py-4">
              <div class="mb-3">
                <label for="example-text-input" class="form-label">
                  Name
                </label>
                <input
                  class="form-control"
                  type="text"
                  value={this.state.edit_name}
                  onChange={(e) => this.setState({ edit_name: e.target.value })}
                />
              </div>
              <div class="mb-3">
                <label for="example-text-input" class="form-label">
                  Team Member Image <span class="text-danger">*</span>
                </label>
                {this.state.is_image_present == true && (
                  <div
                    style={{
                      padding: 10,
                      border: "1px solid #ddd",
                      width: "fit-content",
                    }}
                    class="mb-2"
                  >
                    <a href={this.state.team_image_url} target="_blank">
                      <img
                        src={this.state.team_image_url}
                        style={{ width: 100 }}
                      />
                    </a>
                  </div>
                )}
                <input
                  class="form-control"
                  type="file"
                  onChange={(e) =>
                    this.setState({ edit_image: e.target.files[0] })
                  }
                />
              </div>
            </div>
          </Spin>
        </Modal>

        <Modal
          title="Delete Project"
          visible={this.state.delete_modal_status}
          onOk={() => this.delete_project()}
          onCancel={() => this.close_delete_modal()}
          okText="Delete"
          cancelText="Cancel"
        >
          <Spin spinning={this.state.formloader}>
            <div class="pt-4">
              <p style={{ fontSize: 16 }}>Are you sure you want to delete?</p>
            </div>
          </Spin>
        </Modal>
      </div>
    );
  }
}
