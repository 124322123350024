// let BASE_URL = "http://127.0.0.1:8000/api/";
// let image_url = "http://127.0.0.1:8000/";

let BASE_URL = "https://api.urbanspell.in/api/";
let image_url = "https://api.urbanspell.in/";

let URLs = {
  image_url: image_url,
  login: BASE_URL + "admin/login",
  login: BASE_URL + "admin/login",
  change_password: BASE_URL + "admin/change-password",

  get_hero_section_settings: BASE_URL + "admin/get_hero_section_settings",
  save_hero_section_settings: BASE_URL + "admin/save_hero_section_settings",
  remove_hero_image: BASE_URL + "admin/remove_hero_image",

  get_service_setting_details: BASE_URL + "admin/get_service_setting_details",
  save_service_section_settings:
    BASE_URL + "admin/save_service_section_settings",

  get_service_list: BASE_URL + "admin/get_service_list",
  save_service: BASE_URL + "admin/save_service",
  update_service: BASE_URL + "admin/update_service",
  delete_service: BASE_URL + "admin/delete_service",

  get_who_we_list: BASE_URL + "admin/who_we_are/list",
  save_who_we_are: BASE_URL + "admin/who_we_are/save",
  update_who_we_are: BASE_URL + "admin/who_we_are/update",
  delete_who_we_are: BASE_URL + "admin/who_we_are/delete",

  get_project_setting_details: BASE_URL + "admin/get_project_setting_details",
  save_project_section_settings:
    BASE_URL + "admin/save_project_section_settings",

  get_project_list: BASE_URL + "admin/project/list",
  save_project: BASE_URL + "admin/project/save",
  update_project: BASE_URL + "admin/project/update",
  delete_project: BASE_URL + "admin/project/delete",

  get_client_settings: BASE_URL + "admin/client/get_settings",
  save_client_settings: BASE_URL + "admin/client/save_settings",

  get_client_list: BASE_URL + "admin/client/list",
  save_client: BASE_URL + "admin/client/save",
  update_client: BASE_URL + "admin/client/update",
  delete_client: BASE_URL + "admin/client/delete",

  get_project_list: BASE_URL + "admin/project/list",
  save_project: BASE_URL + "admin/project/save",
  update_project: BASE_URL + "admin/project/update",
  delete_project: BASE_URL + "admin/project/delete",

  get_blog_post_list: BASE_URL + "admin/blog_post/list",
  save_blog_post: BASE_URL + "admin/blog_post/save",
  update_blog_post: BASE_URL + "admin/blog_post/update",
  delete_blog_post: BASE_URL + "admin/blog_post/delete",

  get_subscriber_list: BASE_URL + "admin/subscriber/list",

  get_team_settings: BASE_URL + "admin/team/get_settings",
  save_team_settings: BASE_URL + "admin/team/save_settings",

  get_team_list: BASE_URL + "admin/team/list",
  // save_team: BASE_URL + "admin/team/save",
  update_team: BASE_URL + "admin/team/update",
  // delete_team: BASE_URL + "admin/team/delete",

  get_testimonial_setting: BASE_URL + "admin/get_testimonial_setting",
  save_testimonial_setting: BASE_URL + "admin/save_testimonial_settings",

  get_testimonial_list: BASE_URL + "admin/testimonial/list",
  save_testimonial: BASE_URL + "admin/testimonial/save",
  update_testimonial: BASE_URL + "admin/testimonial/update",
  delete_testimonial: BASE_URL + "admin/testimonial/delete",

  get_cta_setting: BASE_URL + "admin/get_cta_setting",
  save_cta_setting: BASE_URL + "admin/save_cta_settings",
  get_dashboard_counts: BASE_URL + "admin/get_dashboard_counts",

  get_mission_list: BASE_URL + "admin/mission_vision/get",
  save_mission_record: BASE_URL + "admin/mission_vision/save",

  get_countdown_list: BASE_URL + "admin/countdown/get",
  save_countdown_record: BASE_URL + "admin/countdown/save",

  get_post_settings: BASE_URL + "admin/post_setting/get",
  save_post_setting: BASE_URL + "admin/post_setting/save",

  get_social_icon_list: BASE_URL + "admin/social-icons/get",

  get_socialmedia_list: BASE_URL + "admin/social-media/list",
  save_socialmedia: BASE_URL + "admin/social-media/save",
  update_socialmedia: BASE_URL + "admin/social-media/update",
  delete_socialmedia: BASE_URL + "admin/social-media/delete",

  get_header_settings: BASE_URL + "admin/header_setting/get",
  save_header_setting: BASE_URL + "admin/header_setting/save",

  save_footer_setting: BASE_URL + "admin/footer_setting/save",
  save_contact_settings: BASE_URL + "admin/contact/save",

  get_meta_settings: BASE_URL + "admin/meta-setting/get",
  save_meta_setting: BASE_URL + "admin/meta-setting/save",
};

export default URLs;
