import React, { Component } from "react";
// import "boxicons";

export default class Sidebar extends Component {
  render() {
    return (
      <div class="vertical-menu">
        <div data-simplebar class="h-100">
          <div id="sidebar-menu">
            <ul class="metismenu list-unstyled" id="side-menu">
              <li>
                <a href="/dashboard">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather feather-home"
                  >
                    <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path>
                    <polyline points="9 22 9 12 15 12 15 22"></polyline>
                  </svg>
                  <span data-key="t-dashboard">Dashboard</span>
                </a>
              </li>
              <li>
                <a href="/blog-posts">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather feather-file-text"
                  >
                    <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path>
                    <polyline points="14 2 14 8 20 8"></polyline>
                    <line x1="16" y1="13" x2="8" y2="13"></line>
                    <line x1="16" y1="17" x2="8" y2="17"></line>
                    <polyline points="10 9 9 9 8 9"></polyline>
                  </svg>
                  <span data-key="t-dashboard">Blog Posts</span>
                </a>
              </li>
              <li>
                <a href="/subscribers">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather feather-briefcase"
                  >
                    <rect
                      x="2"
                      y="7"
                      width="20"
                      height="14"
                      rx="2"
                      ry="2"
                    ></rect>
                    <path d="M16 21V5a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v16"></path>
                  </svg>
                  <span data-key="t-dashboard">Subscriber Users</span>
                </a>
              </li>
              <li>
                <a href="javascript: void(0);" class="has-arrow">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather feather-cpu"
                  >
                    <rect
                      x="4"
                      y="4"
                      width="16"
                      height="16"
                      rx="2"
                      ry="2"
                    ></rect>
                    <rect x="9" y="9" width="6" height="6"></rect>
                    <line x1="9" y1="1" x2="9" y2="4"></line>
                    <line x1="15" y1="1" x2="15" y2="4"></line>
                    <line x1="9" y1="20" x2="9" y2="23"></line>
                    <line x1="15" y1="20" x2="15" y2="23"></line>
                    <line x1="20" y1="9" x2="23" y2="9"></line>
                    <line x1="20" y1="14" x2="23" y2="14"></line>
                    <line x1="1" y1="9" x2="4" y2="9"></line>
                    <line x1="1" y1="14" x2="4" y2="14"></line>
                  </svg>
                  <span data-key="t-apps">Web Settings</span>
                </a>
                <ul class="sub-menu" aria-expanded="false">
                  <li>
                    <a href="/page-settings">
                      <span data-key="t-chat">Page Settings</span>
                    </a>
                  </li>
                  <li>
                    <a href="/meta-tag-settings">
                      <span data-key="t-chat">Meta Tag Settings</span>
                    </a>
                  </li>
                  {/* <li>
                    <a href="/backup-settings">
                      <span data-key="t-chat">Backup</span>
                    </a>
                  </li> */}
                </ul>
              </li>
              <li>
                <a href="https://business.facebook.com/" target="_blank">
                  <box-icon
                    type="logo"
                    name="meta"
                    color="#545a6d"
                    size={"20px"}
                    style={{
                      fontWeight: "700",
                      position: "relative",
                      top: 4,
                      paddingRight: 6,
                    }}
                  ></box-icon>
                  <span data-key="t-chat">Meta Suite</span>
                </a>
              </li>
              <li>
                <a
                  href="https://analytics.google.com/analytics/web"
                  target="_blank"
                >
                  <box-icon
                    type="logo"
                    name="google"
                    color="#545a6d"
                    size={"20px"}
                    style={{
                      fontWeight: "700",
                      position: "relative",
                      top: 4,
                      paddingRight: 6,
                    }}
                  ></box-icon>
                  <span data-key="t-chat">Google Analytics</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}
