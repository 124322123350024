import React, { Component } from "react";
import { Spin, message } from "antd";
import axios from "axios";
import URLs from "../../utils/apis";

export default class Countdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "",
      description: "",
      input_placeholder: "",
      hero_img: "",
      hero_img_url: "",
      is_image_present: "",
      loading: false,

      heading: "",
      founded: "",
      projects: "",
      employee: "",
      satisfied_clients: "",
      story_link: "",
      our_story_title: "",
    };
  }
  componentDidMount() {
    this.get_details();
  }
  get_details = () => {
    this.setState({ loading: true });
    axios
      .get(URLs.get_countdown_list)
      .then((response) => {
        if (response.data.status == "1") {
          let currentObj = response.data.data;
          this.setState({
            heading: currentObj.heading,
            founded: currentObj.founded,
            projects: currentObj.projects,
            employee: currentObj.employee,
            satisfied_clients: currentObj.satisfied_clients,
            story_link: currentObj.story_link,
            our_story_title: currentObj.our_story_title,
            loading: false,
          });
        } else {
          this.setState({ loading: false });
          return;
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
        console.error("Warning:", error);
        // message.warning("Please try again.");
      });
  };
  save = () => {
    if (!this.state.heading) {
      message.warning("Countdown Title is required");
      return;
    }
    if (!this.state.founded) {
      message.warning("Please enter Founded count value");
      return;
    }
    if (!this.state.projects) {
      message.warning("Please enter Project count value");
      return;
    }
    if (!this.state.employee) {
      message.warning("Please enter Employee count value");
      return;
    }
    if (!this.state.satisfied_clients) {
      message.warning("Please enter Satisfied Clients count value");
      return;
    }
    if (!this.state.our_story_title) {
      message.warning("Please enter Our Story title");
      return;
    }
    if (!this.state.story_link) {
      message.warning("Please enter our story title");
      return;
    }
    this.setState({ loading: true });
    let formdata = new FormData();
    formdata.append("heading", this.state.heading);
    formdata.append("founded", this.state.founded);
    formdata.append("projects", this.state.projects);
    formdata.append("employee", this.state.employee);
    formdata.append("satisfied_clients", this.state.satisfied_clients);
    formdata.append("our_story_title", this.state.our_story_title);
    formdata.append("story_link", this.state.story_link);
    axios
      .post(URLs.save_countdown_record, formdata)
      .then((response) => {
        if (response.data.status == "1") {
          message.success(response.data.message);
          this.setState(
            {
              loading: false,
            },
            () => this.get_details()
          );
        } else {
          message.warning(response.data.message);
          this.setState({ loading: false });
          return;
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
        console.error("Warning:", error);
        // message.warning("Please try again.");
      });
  };
  render() {
    return (
      <div class="card">
        <div class="card-header">
          <h4 class="card-title" style={{ fontSize: 16, marginBottom: 0 }}>
            Hero Section Settings
          </h4>
        </div>
        <Spin spinning={this.state.loading}>
          <div class="card-body p-4">
            <div class="row">
              <div class="col-lg-12">
                <div>
                  <div class="mb-3">
                    <label for="example-text-input" class="form-label">
                      Countdown Title <span class="text-danger">*</span>
                    </label>
                    <input
                      class="form-control"
                      type="text"
                      value={this.state.heading}
                      onChange={(e) =>
                        this.setState({ heading: e.target.value })
                      }
                    />
                  </div>
                  <div class="row">
                    <div class="mb-3 col-lg-3">
                      <label for="example-email-input" class="form-label">
                        Founded <span class="text-danger">*</span>
                      </label>
                      <input
                        class="form-control"
                        type="text"
                        id="example-email-input"
                        value={this.state.founded}
                        onChange={(e) =>
                          this.setState({ founded: e.target.value })
                        }
                      />
                    </div>
                    <div class="mb-3 col-lg-3">
                      <label for="example-email-input" class="form-label">
                        Projects <span class="text-danger">*</span>
                      </label>
                      <input
                        class="form-control"
                        type="text"
                        id="example-email-input"
                        value={this.state.projects}
                        onChange={(e) =>
                          this.setState({ projects: e.target.value })
                        }
                      />
                    </div>
                    <div class="mb-3 col-lg-3">
                      <label for="example-email-input" class="form-label">
                        Employee <span class="text-danger">*</span>
                      </label>
                      <input
                        class="form-control"
                        type="text"
                        id="example-email-input"
                        value={this.state.employee}
                        onChange={(e) =>
                          this.setState({ employee: e.target.value })
                        }
                      />
                    </div>
                    <div class="mb-3 col-lg-3">
                      <label for="example-email-input" class="form-label">
                        Satisfied Clients <span class="text-danger">*</span>
                      </label>
                      <input
                        class="form-control"
                        type="text"
                        id="example-email-input"
                        value={this.state.satisfied_clients}
                        onChange={(e) =>
                          this.setState({ satisfied_clients: e.target.value })
                        }
                      />
                    </div>
                  </div>

                  <div class="mb-3">
                    <label for="example-text-input" class="form-label">
                      Our Story Title <span class="text-danger">*</span>
                    </label>
                    <input
                      class="form-control"
                      type="text"
                      value={this.state.our_story_title}
                      onChange={(e) =>
                        this.setState({ our_story_title: e.target.value })
                      }
                    />
                  </div>
                  <div class="mb-3">
                    <label for="example-text-input" class="form-label">
                      Our Story Link <span class="text-danger">*</span>
                    </label>
                    <input
                      class="form-control"
                      type="text"
                      value={this.state.story_link}
                      onChange={(e) =>
                        this.setState({ story_link: e.target.value })
                      }
                    />
                  </div>
                  <div class="mt-4">
                    <button
                      type="button"
                      onClick={() => this.save()}
                      class="btn btn-primary w-md"
                    >
                      Update
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Spin>
      </div>
    );
  }
}
