import React, { Component } from "react";
import Header from "./includes/Header";
import Sidebar from "./includes/Sidebar";
import Footer from "./includes/Footer";
import URLs from "./utils/apis";
import axios from "axios";
import CopyRight from "./includes/CopyRight";

export default class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      blog_count: 0,
      sub_count: 0,
      loading: false,
    };
  }
  componentDidMount() {
    this.get_data();
  }
  get_data = () => {
    this.setState({ loading: true });
    axios
      .get(URLs.get_dashboard_counts)
      .then((response) => {
        if (response.data.status == "1") {
          this.setState({
            blog_count: response.data.blog_count,
            sub_count: response.data.sub_count,
            loading: false,
          });
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
        console.error("Warning:", error);
      });
  };
  render() {
    return (
      <div id="layout-wrapper">
        <Header />
        <Sidebar />
        <div class="main-content">
          <div class="page-content">
            <div class="container-fluid">
              <div class="row">
                <div class="col-12">
                  <div class="page-title-box d-sm-flex align-items-center justify-content-between">
                    <h4 class="mb-sm-0 font-size-18">Dashboard</h4>

                    <div class="page-title-right">
                      <ol class="breadcrumb m-0">
                        <li class="breadcrumb-item">
                          <a href="javascript: void(0);">Dashboard</a>
                        </li>
                        <li class="breadcrumb-item active">Dashboard</li>
                      </ol>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-xl-3 col-md-6">
                  <div class="card">
                    <div class="card-body">
                      <div class="row align-items-center">
                        <div class="col-12">
                          <span class="text-muted mb-3 lh-1 d-block">
                            Total Subscribers
                          </span>
                          <h4>
                            <span class="counter-value" data-target="865.2">
                              {this.state.sub_count}
                            </span>
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-xl-3 col-md-6">
                  <div class="card">
                    <div class="card-body">
                      <div class="row align-items-center">
                        <div class="col-12">
                          <span class="text-muted mb-3 lh-1 d-block">
                            Blog Posts
                          </span>
                          <h4>
                            <span class="counter-value" data-target="6258">
                              {this.state.blog_count}
                            </span>
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <CopyRight />
        </div>
      </div>
    );
  }
}
