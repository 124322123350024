import React, { Component } from "react";
import { Spin, message, Table, Modal } from "antd";
import axios from "axios";
import URLs from "../../utils/apis";
import { TagsInput } from "react-tag-input-component";
import FullEditor from "ckeditor5-build-full";
import { CKEditor } from "@ckeditor/ckeditor5-react";

export default class Projects extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "",
      description: "",
      input_placeholder: "",
      loading: false,
      listLoader: false,
      project_list: [],

      add_modal_status: false,
      client_name: "",
      info_data: [
        {
          id: 1,
          title: "",
          desc: "",
        },
      ],
      project_name: "",
      project_description: "",
      project_image: "",
      problem_statement: [
        {
          id: 1,
          title: "",
        },
      ],
      solution_results: [
        {
          id: 1,
          title: "",
        },
      ],
      formloader: false,

      p_id: 0,
      edit_modal_status: false,

      edit_client_name: "",
      edit_info_data: [
        {
          id: 1,
          title: "",
          desc: "",
        },
      ],
      edit_project_name: "",
      edit_project_description: "",
      edit_project_image: "",
      edit_problem_statement: [{ id: 1, title: "" }],
      edit_solution_results: [{ id: 1, title: "" }],
      project_image_url: "",
      is_image_present: false,

      delete_modal_status: false,
    };
  }
  componentDidMount() {
    this.get_details();
    this.get_project_list();
  }
  get_details = () => {
    this.setState({ loading: true });
    axios
      .get(URLs.get_project_setting_details)
      .then((response) => {
        if (response.data.status == "1") {
          let currentObj = response.data.data;
          console.log("project", currentObj);
          this.setState({
            title: currentObj.title,
            description: currentObj.description,
            loading: false,
          });
        } else {
          this.setState({ loading: false });
          return;
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
        console.error("Warning:", error);
        // message.warning("Please try again.");
      });
  };
  save_settings = () => {
    if (!this.state.title) {
      message.warning("Title is required");
      return;
    }
    if (!this.state.description) {
      message.warning("Description is required");
      return;
    }
    this.setState({ loading: true });
    let params = {
      title: this.state.title,
      description: this.state.description,
    };
    axios
      .post(URLs.save_project_section_settings, params)
      .then((response) => {
        if (response.data.status == "1") {
          message.success(response.data.message);
          this.setState(
            {
              loading: false,
            },
            () => this.get_details()
          );
        } else {
          message.warning(response.data.message);
          this.setState({ loading: false });
          return;
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
        console.error("Warning:", error);
        // message.warning("Please try again.");
      });
  };
  get_project_list = () => {
    this.setState({ listLoader: true });
    axios
      .get(URLs.get_project_list)
      .then((response) => {
        if (response.data.status == "1") {
          let currentObj = response.data.data;
          this.setState({
            project_list: response.data.data,
            listLoader: false,
          });
        } else {
          this.setState({ listLoader: false });
          return;
        }
      })
      .catch((error) => {
        this.setState({ listLoader: false });
      });
  };
  save_project = () => {
    if (!this.state.client_name) {
      message.warning("Client name is required");
      return;
    }
    if (!this.state.project_name) {
      message.warning("Project name is required");
      return;
    }
    if (!this.state.project_description) {
      message.warning("Project description is required");
      return;
    }
    if (!this.state.project_image) {
      message.warning("Please select project image");
      return;
    }
    if (this.state.problem_statement.length == 0) {
      message.warning("Please add problem statement for project");
      return;
    }
    if (this.state.problem_statement.length > 0) {
      let list = this.state.problem_statement;
      for (let item of list) {
        if (!item.title) {
          message.warning("Please enter the title of problem statement");
          return;
        }
      }
    }
    if (this.state.solution_results.length == 0) {
      message.warning("Please add solutions & results for project");
      return;
    }
    if (this.state.solution_results.length > 0) {
      let list = this.state.solution_results;
      for (let item of list) {
        if (!item.title) {
          message.warning("Please enter the title of Solutions & Results");
          return;
        }
      }
    }
    if (this.state.info_data.length == 0) {
      message.warning("Please add info data for projects");
      return;
    }
    if (this.state.info_data.length > 0) {
      let list = this.state.info_data;
      for (let item of list) {
        if (!item.title) {
          message.warning("Please enter the title of Info List");
          return;
        }
        if (!item.desc) {
          message.warning("Please enter the Description of Info List");
          return;
        }
      }
    }
    this.setState({ formloader: true });
    let formData = new FormData();
    formData.append("client_name", this.state.client_name);
    formData.append("project_name", this.state.project_name);
    formData.append("description", this.state.project_description);
    formData.append("project_image", this.state.project_image);
    formData.append("info_data", JSON.stringify(this.state.info_data));
    formData.append(
      "problem_statement",
      JSON.stringify(this.state.problem_statement)
    );
    formData.append(
      "solution_results",
      JSON.stringify(this.state.solution_results)
    );
    axios
      .post(URLs.save_project, formData)
      .then((response) => {
        if (response.data.status == "1") {
          message.success(response.data.message);
          this.setState(
            {
              formloader: false,
              add_modal_status: false,
              client_name: "",
              info_data: [
                {
                  id: 1,
                  title: "",
                  desc: "",
                },
              ],
              project_name: "",
              project_description: "",
              project_image: "",
              problem_statement: [
                {
                  id: 1,
                  title: "",
                },
              ],
              solution_results: [
                {
                  id: 1,
                  title: "",
                },
              ],
            },
            () => this.get_project_list()
          );
        } else {
          message.warning(response.data.message);
          this.setState({ formloader: false });
          return;
        }
      })
      .catch((error) => {
        this.setState({ formloader: false });
        console.error("Warning:", error);
        message.warning("Please try again.");
      });
  };
  open_add_modal = () => {
    this.setState({
      add_modal_status: true,
      client_name: "",
      info_data: [
        {
          id: 1,
          title: "",
          desc: "",
        },
      ],
      project_name: "",
      project_description: "",
      project_image: "",
      problem_statement: [
        {
          id: 1,
          title: "",
        },
      ],
      solution_results: [
        {
          id: 1,
          title: "",
        },
      ],
    });
  };
  close_save_modal = () => {
    this.setState({
      add_modal_status: false,
      client_name: "",
      info_data: [
        {
          id: 1,
          title: "",
          desc: "",
        },
      ],
      project_name: "",
      project_description: "",
      project_image: "",
      problem_statement: [],
      solution_results: [],
    });
  };
  open_edit_modal = (item) => {
    let banner_url = URLs.image_url + item.image;
    this.setState({
      edit_modal_status: true,
      edit_client_name: item.client_name,
      edit_info_data: item.info_data
        ? JSON.parse(item.info_data)
        : [
            {
              id: 1,
              title: "",
              desc: "",
            },
          ],
      edit_project_name: item.name,
      edit_project_description: item.description,
      edit_project_image: "",
      edit_problem_statement: item.problem_statement
        ? JSON.parse(item.problem_statement)
        : [{ id: 1, title: "" }],
      edit_solution_results: item.solution_results
        ? JSON.parse(item.solution_results)
        : [{ id: 1, title: "" }],

      project_image_url: banner_url,
      p_id: item.p_id,
      is_image_present: item.image ? true : false,
    });
  };
  close_update_modal = () => {
    this.setState({
      is_image_present: false,
      p_id: "",
      edit_modal_status: false,
      client_name: "",
      info_data: [
        {
          id: 1,
          title: "",
          desc: "",
        },
      ],
      project_name: "",
      project_description: "",
      project_image: "",
      problem_statement: [
        {
          id: 1,
          title: "",
        },
      ],
      solution_results: [
        {
          id: 1,
          title: "",
        },
      ],
    });
  };
  close_save_modal = () => {
    this.setState({
      add_modal_status: false,
      client_name: "",
      info_data: [
        {
          id: 1,
          title: "",
          desc: "",
        },
      ],
      project_name: "",
      project_description: "",
      project_image: "",
      problem_statement: [
        {
          id: 1,
          title: "",
        },
      ],
      solution_results: [
        {
          id: 1,
          title: "",
        },
      ],
    });
  };

  update_project = () => {
    if (!this.state.edit_client_name) {
      message.warning("Client name is required");
      return;
    }
    if (!this.state.edit_project_name) {
      message.warning("Project name is required");
      return;
    }
    if (!this.state.edit_project_description) {
      message.warning("Project description is required");
      return;
    }

    if (this.state.edit_problem_statement.length == 0) {
      message.warning("Please add problem statement for project");
      return;
    }
    if (this.state.edit_problem_statement.length > 0) {
      let list = this.state.edit_problem_statement;
      for (let item of list) {
        if (!item.title) {
          message.warning("Please enter the title of problem statement");
          return;
        }
      }
    }
    if (this.state.edit_solution_results.length == 0) {
      message.warning("Please add solutions & results for project");
      return;
    }
    if (this.state.edit_solution_results.length > 0) {
      let list = this.state.edit_solution_results;
      for (let item of list) {
        if (!item.title) {
          message.warning("Please enter the title of Solutions & Results");
          return;
        }
      }
    }
    if (this.state.edit_info_data.length == 0) {
      message.warning("Please add info data for projects");
      return;
    }
    if (this.state.edit_info_data.length > 0) {
      let list = this.state.edit_info_data;
      for (let item of list) {
        if (!item.title) {
          message.warning("Please enter the title of Info List");
          return;
        }
        if (!item.desc) {
          message.warning("Please enter the Description of Info List");
          return;
        }
      }
    }
    this.setState({ formloader: true });
    let formData = new FormData();
    formData.append("client_name", this.state.edit_client_name);
    formData.append("project_name", this.state.edit_project_name);
    formData.append("description", this.state.edit_project_description);
    formData.append("project_image", this.state.edit_project_image);
    formData.append("info_data", JSON.stringify(this.state.edit_info_data));
    formData.append(
      "problem_statement",
      JSON.stringify(this.state.edit_problem_statement)
    );
    formData.append(
      "solution_results",
      JSON.stringify(this.state.edit_solution_results)
    );
    formData.append("p_id", this.state.p_id);
    axios
      .post(URLs.update_project, formData)
      .then((response) => {
        if (response.data.status == "1") {
          message.success(response.data.message);
          this.setState(
            {
              formloader: false,
              edit_modal_status: false,
              edit_client_name: "",
              edit_info_data: [
                {
                  id: 1,
                  title: "",
                  desc: "",
                },
              ],
              edit_project_name: "",
              edit_project_description: "",
              edit_project_image: "",
              edit_problem_statement: [
                {
                  id: 1,
                  title: "",
                },
              ],
              edit_solution_results: [
                {
                  id: 1,
                  title: "",
                },
              ],
              p_id: 0,
            },
            () => this.get_project_list()
          );
        } else {
          message.warning(response.data.message);
          this.setState({ formloader: false });
          return;
        }
      })
      .catch((error) => {
        this.setState({ formloader: false });
        console.error("Warning:", error);
        message.warning("Please try again.");
      });
  };
  close_delete_modal = () => {
    this.setState({
      delete_modal_status: false,
      p_id: "",
    });
  };
  open_delete_modal = (item) => {
    this.setState({
      delete_modal_status: true,
      p_id: item.p_id,
    });
  };
  delete_project = () => {
    let params = {
      p_id: this.state.p_id,
    };
    axios
      .post(URLs.delete_project, params)
      .then((response) => {
        if (response.data.status == "1") {
          message.success(response.data.message);
          this.setState(
            {
              formloader: false,
              delete_modal_status: false,
              p_id: 0,
            },
            () => this.get_project_list()
          );
        } else {
          message.warning(response.data.message);
          this.setState({ formloader: false });
          return;
        }
      })
      .catch((error) => {
        this.setState({ formloader: false });
        console.error("Warning:", error);
      });
  };
  getRandomInt = (min, max) => {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
  };

  // add
  add_new_info = () => {
    let arr = this.state.info_data;
    const randomInteger = this.getRandomInt(2, 2000);
    let obj = {
      id: randomInteger,
      title: "",
      desc: "",
    };
    arr.push(obj);
    this.setState({ info_data: arr });
  };
  remove_info = (id) => {
    let arr = this.state.info_data;
    let tempArr = [];
    for (let single of arr) {
      if (single.id != id) {
        tempArr.push(single);
      }
    }
    this.setState({ info_data: tempArr });
  };
  update_info = (event, item, field) => {
    let arr = this.state.info_data;
    let tempArr = [];
    for (let single of arr) {
      if (single.id == item.id) {
        if (field == "title") {
          single.title = event.target.value;
        }
        if (field == "desc") {
          single.desc = event.target.value;
        }
      }
      tempArr.push(single);
    }
    this.setState({ info_data: tempArr });
  };

  add_new_solution = () => {
    let arr = this.state.solution_results;
    const randomInteger = this.getRandomInt(2, 2000);
    let obj = {
      id: randomInteger,
      title: "",
    };
    arr.push(obj);
    this.setState({ solution_results: arr });
  };
  remove_solution = (id) => {
    let arr = this.state.solution_results;
    let tempArr = [];
    for (let single of arr) {
      if (single.id != id) {
        tempArr.push(single);
      }
    }
    this.setState({ solution_results: tempArr });
  };
  update_solution = (event, item, field) => {
    let arr = this.state.solution_results;
    let tempArr = [];
    for (let single of arr) {
      if (single.id == item.id) {
        if (field == "title") {
          single.title = event.target.value;
        }
      }
      tempArr.push(single);
    }
    this.setState({ solution_results: tempArr });
  };

  add_new_problem = () => {
    let arr = this.state.problem_statement;
    const randomInteger = this.getRandomInt(2, 2000);
    let obj = {
      id: randomInteger,
      title: "",
      desc: "",
    };
    arr.push(obj);
    this.setState({ problem_statement: arr });
  };
  remove_problem = (id) => {
    let arr = this.state.problem_statement;
    let tempArr = [];
    for (let single of arr) {
      if (single.id != id) {
        tempArr.push(single);
      }
    }
    this.setState({ problem_statement: tempArr });
  };
  update_problem = (event, item, field) => {
    let arr = this.state.problem_statement;
    let tempArr = [];
    for (let single of arr) {
      if (single.id == item.id) {
        if (field == "title") {
          single.title = event.target.value;
        }
      }
      tempArr.push(single);
    }
    this.setState({ problem_statement: tempArr });
  };

  // edit
  edit_add_new_info = () => {
    let arr = this.state.edit_info_data;
    const randomInteger = this.getRandomInt(2, 2000);
    let obj = {
      id: randomInteger,
      title: "",
      desc: "",
    };
    arr.push(obj);
    this.setState({ edit_info_data: arr });
  };
  edit_remove_info = (id) => {
    let arr = this.state.edit_info_data;
    let tempArr = [];
    for (let single of arr) {
      if (single.id != id) {
        tempArr.push(single);
      }
    }
    this.setState({ edit_info_data: tempArr });
  };
  edit_update_info = (event, item, field) => {
    let arr = this.state.edit_info_data;
    let tempArr = [];
    for (let single of arr) {
      if (single.id == item.id) {
        if (field == "title") {
          single.title = event.target.value;
        }
        if (field == "desc") {
          single.desc = event.target.value;
        }
      }
      tempArr.push(single);
    }
    this.setState({ edit_info_data: tempArr });
  };

  edit_add_new_solution = () => {
    let arr = this.state.edit_solution_results;
    const randomInteger = this.getRandomInt(2, 2000);
    let obj = {
      id: randomInteger,
      title: "",
    };
    arr.push(obj);
    this.setState({ edit_solution_results: arr });
  };
  edit_remove_solution = (id) => {
    let arr = this.state.edit_solution_results;
    let tempArr = [];
    for (let single of arr) {
      if (single.id != id) {
        tempArr.push(single);
      }
    }
    this.setState({ edit_solution_results: tempArr });
  };
  edit_update_solution = (event, item, field) => {
    let arr = this.state.edit_solution_results;
    let tempArr = [];
    for (let single of arr) {
      if (single.id == item.id) {
        if (field == "title") {
          single.title = event.target.value;
        }
      }
      tempArr.push(single);
    }
    this.setState({ edit_solution_results: tempArr });
  };

  edit_add_new_problem = () => {
    let arr = this.state.edit_problem_statement;
    const randomInteger = this.getRandomInt(2, 2000);
    let obj = {
      id: randomInteger,
      title: "",
      desc: "",
    };
    arr.push(obj);
    this.setState({ edit_problem_statement: arr });
  };
  edit_remove_problem = (id) => {
    let arr = this.state.edit_problem_statement;
    let tempArr = [];
    for (let single of arr) {
      if (single.id != id) {
        tempArr.push(single);
      }
    }
    this.setState({ edit_problem_statement: tempArr });
  };
  edit_update_problem = (event, item, field) => {
    let arr = this.state.edit_problem_statement;
    let tempArr = [];
    for (let single of arr) {
      if (single.id == item.id) {
        if (field == "title") {
          single.title = event.target.value;
        }
      }
      tempArr.push(single);
    }
    this.setState({ edit_problem_statement: tempArr });
  };

  render() {
    const columns = [
      {
        title: "Project Name",
        dataIndex: "pname",
        key: "pname",
        width: 150,
      },
      {
        title: "Client Name",
        dataIndex: "cname",
        key: "cname",
      },
      {
        title: "Action",
        dataIndex: "action",
        key: "action",
        render: (item) => {
          return (
            <div>
              <button
                type="button"
                onClick={() => this.open_edit_modal(item)}
                class="btn btn-primary mb-3"
                style={{ width: 50, height: 35, marginRight: 10 }}
              >
                <box-icon
                  name="edit-alt"
                  color="#ffffff"
                  size={"18px"}
                ></box-icon>
              </button>
              <button
                type="button"
                onClick={() => this.open_delete_modal(item)}
                class="btn btn-danger mb-3"
                style={{ width: 50, height: 35 }}
              >
                <box-icon name="trash" color="white" size={"18px"}></box-icon>
              </button>
            </div>
          );
        },
      },
    ];
    const dataSource = this.state.project_list.map((item, index) => {
      return {
        pname: item.name ? item.name : "---",
        cname: item.client_name ? item.client_name : "---",
        action: item,
      };
    });
    return (
      <div class="card">
        <div class="card-header">
          <h4 class="card-title" style={{ fontSize: 16, marginBottom: 0 }}>
            Recent Projects
          </h4>
        </div>
        <Spin spinning={this.state.loading}>
          <div class="card-body p-4">
            <div class="row">
              <div class="col-lg-12">
                <div>
                  <div class="mb-3">
                    <label for="example-text-input" class="form-label">
                      Title <span class="text-danger">*</span>
                    </label>
                    <input
                      class="form-control"
                      type="text"
                      value={this.state.title}
                      onChange={(e) => this.setState({ title: e.target.value })}
                    />
                  </div>
                  <div class="mb-3">
                    <label for="example-search-input" class="form-label">
                      Description <span class="text-danger">*</span>
                    </label>
                    <textarea
                      class="form-control"
                      rows="3"
                      placeholder=""
                      name="detail"
                      id="detail"
                      spellcheck="false"
                      value={this.state.description}
                      onChange={(e) =>
                        this.setState({ description: e.target.value })
                      }
                    >
                      {this.state.description}
                    </textarea>
                  </div>
                  <div class="mt-4">
                    <button
                      type="button"
                      onClick={() => this.save_settings()}
                      class="btn btn-primary w-md"
                    >
                      Update
                    </button>
                  </div>
                </div>
                <div class="card mt-3 p-3">
                  <button
                    type="button"
                    onClick={() => this.open_add_modal()}
                    class="btn btn-primary mb-3"
                    style={{ width: 180, float: "right", marginLeft: "auto" }}
                  >
                    Add New Project
                  </button>
                  <Table
                    bordered
                    loading={this.state.listLoader}
                    dataSource={dataSource}
                    columns={columns}
                  />
                </div>
              </div>
            </div>
          </div>
        </Spin>

        {/* Add Service Modal  */}
        <Modal
          title="Add New Project"
          visible={this.state.add_modal_status}
          onOk={() => this.save_project()}
          onCancel={() => this.close_save_modal()}
          okText="Save"
          cancelText="Cancel"
        >
          <Spin spinning={this.state.formloader}>
            <div class="py-4">
              <div class="mb-3">
                <label for="example-text-input" class="form-label">
                  Client Name <span class="text-danger">*</span>
                </label>
                <input
                  class="form-control"
                  type="text"
                  value={this.state.client_name}
                  onChange={(e) =>
                    this.setState({ client_name: e.target.value })
                  }
                />
              </div>
              <div class="mb-3">
                <label for="example-text-input" class="form-label">
                  Project Name <span class="text-danger">*</span>
                </label>
                <input
                  class="form-control"
                  type="text"
                  value={this.state.project_name}
                  onChange={(e) =>
                    this.setState({ project_name: e.target.value })
                  }
                />
              </div>
              <div class="mb-3">
                <label for="example-search-input" class="form-label">
                  Description <span class="text-danger">*</span>
                </label>
                {/* <textarea
                  class="form-control"
                  rows="3"
                  placeholder=""
                  name="detail"
                  id="detail"
                  spellcheck="false"
                  value={this.state.project_description}
                  onChange={(e) =>
                    this.setState({ project_description: e.target.value })
                  }
                ></textarea> */}
                <CKEditor
                  data={this.state.project_description}
                  editor={FullEditor}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    this.setState({ project_description: data });
                  }}
                />
              </div>
              <div class="mb-3">
                <label for="example-text-input" class="form-label">
                  Banner Image <span class="text-danger">*</span>
                </label>
                <input
                  class="form-control"
                  type="file"
                  onChange={(e) =>
                    this.setState({ project_image: e.target.files[0] })
                  }
                />
              </div>
              <div class="mb-3">
                <label for="example-text-input" class="form-label">
                  Problem Statement <span class="text-danger">*</span>
                </label>
                <button
                  type="button"
                  class="btn btn-primary mb-3"
                  onClick={() => this.add_new_problem()}
                  style={{ width: 100, float: "right", marginLeft: "auto" }}
                >
                  Add New
                </button>
                <table className="table_option_values">
                  <tr>
                    <th>Title</th>
                    <th>Remove</th>
                  </tr>
                  {this.state.problem_statement &&
                    this.state.problem_statement.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>
                            <input
                              type="text"
                              value={item.title}
                              onChange={(e) =>
                                this.update_problem(e, item, "title")
                              }
                            />
                          </td>
                          <td>
                            <center>
                              <box-icon
                                style={{ cursor: "pointer" }}
                                name="trash"
                                color={"red"}
                                size={"17px"}
                                onClick={() => this.remove_problem(item.id)}
                              ></box-icon>
                            </center>
                          </td>
                        </tr>
                      );
                    })}
                </table>
              </div>
              <div class="mb-3">
                <label for="example-text-input" class="form-label">
                  Solutions & Results <span class="text-danger">*</span>
                </label>
                <button
                  type="button"
                  class="btn btn-primary mb-3"
                  onClick={() => this.add_new_solution()}
                  style={{ width: 100, float: "right", marginLeft: "auto" }}
                >
                  Add New
                </button>
                <table className="table_option_values">
                  <tr>
                    <th>Title</th>
                    <th>Remove</th>
                  </tr>
                  {this.state.solution_results &&
                    this.state.solution_results.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>
                            <input
                              type="text"
                              value={item.title}
                              onChange={(e) =>
                                this.update_solution(e, item, "title")
                              }
                            />
                          </td>
                          <td>
                            <center>
                              <box-icon
                                style={{ cursor: "pointer" }}
                                name="trash"
                                color={"red"}
                                size={"17px"}
                                onClick={() => this.remove_solution(item.id)}
                              ></box-icon>
                            </center>
                          </td>
                        </tr>
                      );
                    })}
                </table>
              </div>
              <div class="mb-3">
                <label for="example-text-input" class="form-label">
                  Info List <span class="text-danger">*</span>
                </label>
                <button
                  type="button"
                  class="btn btn-primary mb-3"
                  onClick={() => this.add_new_info()}
                  style={{ width: 100, float: "right", marginLeft: "auto" }}
                >
                  Add New
                </button>
                <table className="table_option_values">
                  <tr>
                    <th>Title</th>
                    <th>Description</th>
                    <th>Remove</th>
                  </tr>
                  {this.state.info_data &&
                    this.state.info_data.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>
                            <input
                              type="text"
                              value={item.title}
                              onChange={(e) =>
                                this.update_info(e, item, "title")
                              }
                            />
                          </td>
                          <td>
                            <input
                              type="text"
                              value={item.desc}
                              onChange={(e) =>
                                this.update_info(e, item, "desc")
                              }
                            />
                          </td>
                          <td>
                            <center>
                              <box-icon
                                style={{ cursor: "pointer" }}
                                name="trash"
                                color={"red"}
                                size={"17px"}
                                onClick={() => this.remove_info(item.id)}
                              ></box-icon>
                            </center>
                          </td>
                        </tr>
                      );
                    })}
                </table>
              </div>
            </div>
          </Spin>
        </Modal>

        {/* Edit Service Modal  */}
        <Modal
          title="Update Project"
          visible={this.state.edit_modal_status}
          onOk={() => this.update_project()}
          onCancel={() => this.close_update_modal()}
          okText="Update"
          cancelText="Cancel"
        >
          <Spin spinning={this.state.formloader}>
            <div class="py-4">
              <div class="mb-3">
                <label for="example-text-input" class="form-label">
                  Client Name <span class="text-danger">*</span>
                </label>
                <input
                  class="form-control"
                  type="text"
                  value={this.state.edit_client_name}
                  onChange={(e) =>
                    this.setState({ edit_client_name: e.target.value })
                  }
                />
              </div>
              <div class="mb-3">
                <label for="example-text-input" class="form-label">
                  Project Name <span class="text-danger">*</span>
                </label>
                <input
                  class="form-control"
                  type="text"
                  value={this.state.edit_project_name}
                  onChange={(e) =>
                    this.setState({ edit_project_name: e.target.value })
                  }
                />
              </div>
              <div class="mb-3">
                <label for="example-search-input" class="form-label">
                  Description <span class="text-danger">*</span>
                </label>
                {/* <textarea
                  class="form-control"
                  rows="3"
                  placeholder=""
                  name="detail"
                  id="detail"
                  spellcheck="false"
                  value={this.state.edit_project_description}
                  onChange={(e) =>
                    this.setState({ edit_project_description: e.target.value })
                  }
                ></textarea> */}
                <CKEditor
                  data={this.state.edit_project_description}
                  editor={FullEditor}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    this.setState({ edit_project_description: data });
                  }}
                />
              </div>
              <div class="mb-3">
                <label for="example-text-input" class="form-label">
                  Banner Image <span class="text-danger">*</span>
                </label>
                {this.state.is_image_present == true && (
                  <div
                    style={{
                      padding: 10,
                      border: "1px solid #ddd",
                      width: "fit-content",
                    }}
                    class="mb-2"
                  >
                    <a href={this.state.project_image_url} target="_blank">
                      <img
                        src={this.state.project_image_url}
                        style={{ width: 100 }}
                      />
                    </a>
                  </div>
                )}
                <input
                  class="form-control"
                  type="file"
                  onChange={(e) =>
                    this.setState({ edit_project_image: e.target.files[0] })
                  }
                />
              </div>
              <div class="mb-3">
                <label for="example-text-input" class="form-label">
                  Problem Statement <span class="text-danger">*</span>
                </label>
                <button
                  type="button"
                  class="btn btn-primary mb-3"
                  onClick={() => this.edit_add_new_problem()}
                  style={{ width: 100, float: "right", marginLeft: "auto" }}
                >
                  Add New
                </button>
                <table className="table_option_values">
                  <tr>
                    <th>Title</th>
                    <th>Remove</th>
                  </tr>
                  {this.state.edit_problem_statement &&
                    this.state.edit_problem_statement.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>
                            <input
                              type="text"
                              value={item.title}
                              onChange={(e) =>
                                this.edit_update_problem(e, item, "title")
                              }
                            />
                          </td>
                          <td>
                            <center>
                              <box-icon
                                style={{ cursor: "pointer" }}
                                name="trash"
                                color={"red"}
                                size={"17px"}
                                onClick={() =>
                                  this.edit_remove_problem(item.id)
                                }
                              ></box-icon>
                            </center>
                          </td>
                        </tr>
                      );
                    })}
                </table>
              </div>
              <div class="mb-3">
                <label for="example-text-input" class="form-label">
                  Solutions & Results <span class="text-danger">*</span>
                </label>
                <button
                  type="button"
                  class="btn btn-primary mb-3"
                  onClick={() => this.edit_add_new_solution()}
                  style={{ width: 100, float: "right", marginLeft: "auto" }}
                >
                  Add New
                </button>
                <table className="table_option_values">
                  <tr>
                    <th>Title</th>
                    <th>Remove</th>
                  </tr>
                  {this.state.edit_solution_results &&
                    this.state.edit_solution_results.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>
                            <input
                              type="text"
                              value={item.title}
                              onChange={(e) =>
                                this.edit_update_solution(e, item, "title")
                              }
                            />
                          </td>
                          <td>
                            <center>
                              <box-icon
                                style={{ cursor: "pointer" }}
                                name="trash"
                                color={"red"}
                                size={"17px"}
                                onClick={() =>
                                  this.edit_remove_solution(item.id)
                                }
                              ></box-icon>
                            </center>
                          </td>
                        </tr>
                      );
                    })}
                </table>
              </div>
              <div class="mb-3">
                <label for="example-text-input" class="form-label">
                  Info List <span class="text-danger">*</span>
                </label>
                <button
                  type="button"
                  class="btn btn-primary mb-3"
                  onClick={() => this.edit_add_new_info()}
                  style={{ width: 100, float: "right", marginLeft: "auto" }}
                >
                  Add New
                </button>
                <table className="table_option_values">
                  <tr>
                    <th>Title</th>
                    <th>Description</th>
                    <th>Remove</th>
                  </tr>
                  {this.state.edit_info_data &&
                    this.state.edit_info_data.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>
                            <input
                              type="text"
                              value={item.title}
                              onChange={(e) =>
                                this.edit_update_info(e, item, "title")
                              }
                            />
                          </td>
                          <td>
                            <input
                              type="text"
                              value={item.desc}
                              onChange={(e) =>
                                this.edit_update_info(e, item, "desc")
                              }
                            />
                          </td>
                          <td>
                            <center>
                              <box-icon
                                style={{ cursor: "pointer" }}
                                name="trash"
                                color={"red"}
                                size={"17px"}
                                onClick={() => this.edit_remove_info(item.id)}
                              ></box-icon>
                            </center>
                          </td>
                        </tr>
                      );
                    })}
                </table>
              </div>
            </div>
          </Spin>
        </Modal>

        <Modal
          title="Delete Project"
          visible={this.state.delete_modal_status}
          onOk={() => this.delete_project()}
          onCancel={() => this.close_delete_modal()}
          okText="Delete"
          cancelText="Cancel"
        >
          <Spin spinning={this.state.formloader}>
            <div class="pt-4">
              <p style={{ fontSize: 16 }}>Are you sure you want to delete?</p>
            </div>
          </Spin>
        </Modal>
      </div>
    );
  }
}
