import React, { Component } from "react";
import { Spin, message, Switch } from "antd";
import axios from "axios";
import URLs from "../../utils/apis";

export default class Ctc extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "",
      description: "",
      sub_title: "",
      button_text: "",
      button_link: "",
      is_section_visible: "",
      loading: false,
    };
  }
  componentDidMount() {
    this.get_details();
  }
  get_details = () => {
    this.setState({ loading: true });
    axios
      .get(URLs.get_cta_setting)
      .then((response) => {
        if (response.data.status == "1") {
          let currentObj = response.data.data;
          this.setState({
            title: currentObj.title,
            description: currentObj.description,
            sub_title: currentObj.sub_title,
            button_text: currentObj.button_text,
            button_link: currentObj.button_link,
            is_section_visible: currentObj.is_section_visible,
            loading: false,
          });
        } else {
          this.setState({ loading: false });
          return;
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
        console.error("Warning:", error);
      });
  };
  save_settings = () => {
    if (!this.state.title) {
      message.warning("Title is required");
      return;
    }
    if (!this.state.sub_title) {
      message.warning("Sub Title is required");
      return;
    }
    if (!this.state.description) {
      message.warning("Description is required");
      return;
    }
    if (!this.state.button_text) {
      message.warning("Button text is required");
      return;
    }
    if (!this.state.button_link) {
      message.warning("Button link is required");
      return;
    }
    this.setState({ loading: true });
    let params = {
      title: this.state.title,
      description: this.state.description,
      sub_title: this.state.sub_title,
      button_text: this.state.button_text,
      button_link: this.state.button_link,
      is_section_visible: this.state.is_section_visible == true ? 1 : 0,
    };
    axios
      .post(URLs.save_cta_setting, params)
      .then((response) => {
        if (response.data.status == "1") {
          message.success(response.data.message);
          this.setState(
            {
              loading: false,
            },
            () => this.get_details()
          );
        } else {
          message.warning(response.data.message);
          this.setState({ loading: false });
          return;
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
        console.error("Warning:", error);
        // message.warning("Please try again.");
      });
  };
  onChange = (checked) => {
    this.setState({
      is_section_visible: checked == true ? 1 : 0,
    });
  };
  render() {
    return (
      <div class="card">
        <div class="card-header">
          <h4 class="card-title" style={{ fontSize: 16, marginBottom: 0 }}>
            CTA
          </h4>
        </div>
        <Spin spinning={this.state.loading}>
          <div class="card-body p-4">
            <div class="row">
              <div class="col-lg-12">
                <div>
                  <div class="mb-3">
                    <label for="example-text-input" class="form-label">
                      Sub Title <span class="text-danger">*</span>
                    </label>
                    <input
                      class="form-control"
                      type="text"
                      value={this.state.sub_title}
                      onChange={(e) =>
                        this.setState({ sub_title: e.target.value })
                      }
                    />
                  </div>
                  <div class="mb-3">
                    <label for="example-text-input" class="form-label">
                      Title <span class="text-danger">*</span>
                    </label>
                    <input
                      class="form-control"
                      type="text"
                      value={this.state.title}
                      onChange={(e) => this.setState({ title: e.target.value })}
                    />
                  </div>
                  <div class="mb-3">
                    <label for="example-search-input" class="form-label">
                      Description <span class="text-danger">*</span>
                    </label>
                    <textarea
                      class="form-control"
                      rows="3"
                      placeholder=""
                      name="detail"
                      id="detail"
                      spellcheck="false"
                      value={this.state.description}
                      onChange={(e) =>
                        this.setState({ description: e.target.value })
                      }
                    >
                      {this.state.description}
                    </textarea>
                  </div>
                  <div class="mb-3">
                    <label for="example-text-input" class="form-label">
                      Button Text <span class="text-danger">*</span>
                    </label>
                    <input
                      class="form-control"
                      type="text"
                      value={this.state.button_text}
                      onChange={(e) =>
                        this.setState({ button_text: e.target.value })
                      }
                    />
                  </div>
                  <div class="mb-3">
                    <label for="example-text-input" class="form-label">
                      Button Link <span class="text-danger">*</span>
                    </label>
                    <input
                      class="form-control"
                      type="text"
                      value={this.state.button_link}
                      onChange={(e) =>
                        this.setState({ button_link: e.target.value })
                      }
                    />
                  </div>
                  <div class="mb-3">
                    <label
                      for="example-text-input"
                      style={{ marginRight: 30 }}
                      class="form-label"
                    >
                      Is section visible
                    </label>
                    <Switch
                      onChange={this.onChange}
                      checked={
                        this.state.is_section_visible == 1 ? true : false
                      }
                    />
                  </div>
                  <div class="mt-4">
                    <button
                      type="button"
                      onClick={() => this.save_settings()}
                      class="btn btn-primary w-md"
                    >
                      Update
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Spin>
      </div>
    );
  }
}
