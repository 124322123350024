import React, { Component } from "react";

export default class CopyRight extends Component {
  render() {
    return (
      <footer class="footer">
        <div class="container-fluid">
          <div class="row">
            <div class="col-sm-6">
              <script>document.write(new Date().getFullYear())</script> ©
              Copyright UrbanSpell
            </div>
            <div class="col-sm-6">
              <div class="text-sm-end d-none d-sm-block">
                Developed by{" "}
                <a
                  href="https://affixtech.in/"
                  target="_blank"
                  class="text-decoration-underline"
                >
                  Affixtech.in
                </a>
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}
