import React, { Component } from "react";
import Header from "./includes/Header";
import Sidebar from "./includes/Sidebar";
import Footer from "./includes/Footer";
import URLs from "./utils/apis";
import axios from "axios";
import CopyRight from "./includes/CopyRight";
import { Table } from "antd";

import Iframe from "react-iframe";

export default class GoogleAna extends Component {
  constructor(props) {
    super(props);
    this.state = {
      total_users: 0,
      loading: false,
    };
  }
  render() {
    return (
      <div id="layout-wrapper">
        <Header />
        <Sidebar />
        <div class="main-content">
          <div class="page-content">
            <div class="container-fluid">
              <div class="row">
                <div class="col-12">
                  <div class="">
                    <div class="">
                      <div class="row">
                        <div class="col-md-12">
                          {/* <iframe
                            // src="https://analytics.google.com/"
                            src="https://www.google.com/"
                            height="480px"
                            width="100%"
                          ></iframe> */}
                          <Iframe
                            url="https://business.facebook.com/"
                            width={640}
                            height="320px"
                            id=""
                            className=""
                            display="block"
                            position="relative"
                          />
                          {/* <object
                            data="https://google.com/"
                            // type="video/mp4"
                            width="600"
                            height="600"
                          >
                            GeeksforGeeks
                          </object> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <CopyRight />
        </div>
      </div>
    );
  }
}
