import React, { Component } from "react";
import Header from "./includes/Header";
import Sidebar from "./includes/Sidebar";
import Footer from "./includes/Footer";
import URLs from "./utils/apis";
import axios from "axios";
import moment from "moment";
import { Table, message } from "antd";
import CopyRight from "./includes/CopyRight";
import * as XLSX from "xlsx";
import * as FileSaver from "file-saver";

const fileType =
  "application/vnd.openxmlformats-officedocument.spreadsheet.ml.sheet;charset=UTF-8";
const fileExtension = ".xlsx";

export default class Subscribers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      records: [],
      crecords: [],
      loading: false,
      search_text: "",
    };
  }
  componentDidMount() {
    this.get_list();
  }
  get_list = () => {
    this.setState({ loading: true });
    axios
      .get(URLs.get_subscriber_list)
      .then((response) => {
        if (response.data.status == "1") {
          this.setState({
            records: response.data.data,
            crecords: response.data.data,
            loading: false,
          });
        } else {
          this.setState({ loading: false });
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
        console.error("Warning:", error);
      });
  };
  search = () => {
    let text = this.state.search_text;
    let tempArr = [];
    let list = this.state.crecords;
    for (let item of list) {
      if (
        (item.email && item.email.toLowerCase().includes(text.toLowerCase())) ||
        (item.date && item.date == text)
      ) {
        tempArr = [...tempArr, item];
      }
    }
    this.setState({ records: tempArr });
  };
  export_data = () => {
    let arr = [];

    for (let item of this.state.records) {
      let obj = {
        Email: item.email,
        Date: item.date_created
          ? moment(item.date_created).format("DD MMM, YYYY")
          : "",
      };
      arr.push(obj);
    }
    let fileName = "Subscriber list";
    const ws = XLSX.utils.json_to_sheet(arr);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
    message.success("Exported successfully");
  };
  render() {
    const dataSource =
      this.state.records &&
      this.state.records.map((item, index) => {
        return {
          email: item.email,
          date: item.date_created
            ? moment(item.date_created).format("DD MMM, YYYY")
            : "---",
        };
      });
    const columns = [
      {
        title: "Subscriber Email",
        dataIndex: "email",
        key: "email",
      },
      {
        title: "Date",
        dataIndex: "date",
        key: "date",
      },
    ];
    return (
      <div id="layout-wrapper">
        <Header />
        <Sidebar />
        <div class="main-content">
          <div class="page-content">
            <div class="container-fluid">
              <div class="row">
                <div class="col-12">
                  <div class="page-title-box d-sm-flex align-items-center justify-content-between">
                    <h4 class="mb-sm-0 font-size-18">
                      Subscriber List - (Total: {this.state.records.length})
                    </h4>

                    <div class="page-title-right"></div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-12">
                  <div class="card">
                    <div class="card-header row px-0">
                      <div className="col-lg-4">
                        <input
                          className="form-control"
                          placeholder="Search.."
                          value={this.state.search_text}
                          onChange={(e) =>
                            this.setState({ search_text: e.target.value }, () =>
                              this.search()
                            )
                          }
                        />
                      </div>
                      <div className="col-lg-5"></div>
                      <div className="col-lg-3">
                        <a
                          href="#"
                          class="btn btn-primary mb-3"
                          onClick={() => this.export_data()}
                          style={{
                            width: 100,
                            float: "right",
                            marginLeft: "auto",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <box-icon
                            type="solid"
                            name="cloud-download"
                            color="#fff"
                          ></box-icon>
                          Export
                        </a>
                      </div>
                    </div>
                    <div class="card-body">
                      <Table
                        bordered
                        dataSource={dataSource}
                        columns={columns}
                        loading={this.state.loading}
                      />
                      ;
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <CopyRight />
        </div>
      </div>
    );
  }
}
