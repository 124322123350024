import React, { Component } from "react";
import { message, Spin } from "antd";
import URLs from "./utils/apis";
import axios from "axios";

export default class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      loading: false,
      passwordType: "password",
    };
  }
  login = () => {
    if (!this.state.email) {
      message.warning("Email address is required.");
      return;
    }
    if (!this.state.password) {
      message.warning("Password is required.");
      return;
    }
    let params = {
      email: this.state.email,
      password: this.state.password,
    };
    this.setState({ loading: true });
    axios
      .post(URLs.login, params)
      .then((response) => {
        if (response.data.status == "1") {
          message.success(response.data.message);
          localStorage.setItem("usecret_id", response.data.data[0].id);
          localStorage.setItem("usecret_name", response.data.data[0].username);
          this.setState({ loading: false });
          setTimeout(() => {
            window.location.assign("/dashboard");
          }, 2000);
          return;
        } else {
          this.setState({ loading: false });
          message.warning(response.data.message);
          return;
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
        console.error("Warning:", error);
        message.warning("Please try again.");
      });
  };
  togglePass = () => {
    let status = this.state.passwordType == "password" ? "text" : "password";
    this.setState({ passwordType: status });
  };
  render() {
    return (
      <div class="auth-page">
        <div class="container-fluid p-0">
          <div class="row g-0">
            <div class="col-xxl-3 col-lg-4 col-md-5 m-auto">
              <Spin spinning={this.state.loading}>
                <div
                  class="auth-full-page-content d-flex p-sm-5 p-4"
                  style={{
                    border: "1px solid #bcb5b5",
                    minHeight: "fit-content",
                    marginTop: 40,
                    borderRadius: 10,
                  }}
                >
                  <div class="w-100">
                    <div class="d-flex flex-column h-100">
                      <div class="mb-3 text-center">
                        <a href="index.html" class="d-block auth-logo">
                          <img
                            src="assets/images/logo-transparent.webp"
                            alt=""
                            height="100"
                          />{" "}
                        </a>
                      </div>
                      <div class="auth-content my-auto">
                        <div class="text-center">
                          <h5 class="mb-0">Welcome Back !</h5>
                          <p class="text-muted mt-2">
                            Sign in to continue to UrbanSpell
                          </p>
                        </div>
                        <form
                          class="mt-4 pt-2"
                          action="https://themesbrand.com/minia/layouts/index.html"
                        >
                          <div class="mb-3">
                            <label class="form-label">Email address</label>
                            <input
                              type="text"
                              class="form-control"
                              id="email"
                              placeholder="Enter Email"
                              value={this.state.email}
                              onChange={(e) =>
                                this.setState({ email: e.target.value })
                              }
                            />
                          </div>
                          <div class="mb-3">
                            <div class="d-flex align-items-start">
                              <div class="flex-grow-1">
                                <label class="form-label">Password</label>
                              </div>
                              <div class="flex-shrink-0">
                                <div class="">
                                  {/* <a
                                  href="auth-recoverpw.html"
                                  class="text-muted"
                                >
                                  Forgot password?
                                </a> */}
                                </div>
                              </div>
                            </div>

                            <div class="input-group auth-pass-inputgroup">
                              <input
                                type={this.state.passwordType}
                                class="form-control"
                                placeholder="Enter password"
                                aria-label="Password"
                                aria-describedby="password-addon"
                                value={this.state.password}
                                onChange={(e) =>
                                  this.setState({ password: e.target.value })
                                }
                              />
                              <button
                                class="btn btn-light shadow-none ms-0"
                                type="button"
                                id="password-addon"
                                onClick={() => this.togglePass()}
                              >
                                <i class="mdi mdi-eye-outline"></i>
                              </button>
                            </div>
                          </div>

                          <div class="mb-3 mt-4">
                            <button
                              class="btn btn-primary w-100 waves-effect waves-light"
                              type="button"
                              onClick={() => this.login()}
                            >
                              Log In
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </Spin>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
