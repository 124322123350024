import React, { Component } from "react";
import { Spin, message, Table, Modal } from "antd";
import axios from "axios";
import URLs from "../../utils/apis";
import { TagsInput } from "react-tag-input-component";

export default class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      button_link: "",
      address: "",
      footer_logo: "",
      footer_logo_link: "",

      title: "",
      description: "",
      input_placeholder: "",
      loading: false,
      listLoader: false,
      project_list: [],

      add_modal_status: false,
      client_name: "",
      info_data: [
        {
          id: 1,
          title: "",
          desc: "",
        },
      ],
      project_name: "",
      project_description: "",
      project_image: "",
      problem_statement: [
        {
          id: 1,
          title: "",
        },
      ],
      solution_results: [
        {
          id: 1,
          title: "",
        },
      ],
      formloader: false,

      p_id: 0,
      edit_modal_status: false,

      edit_client_name: "",
      edit_info_data: [
        {
          id: 1,
          title: "",
          desc: "",
        },
      ],
      edit_project_name: "",
      edit_project_description: "",
      edit_project_image: "",
      edit_problem_statement: [{ id: 1, title: "" }],
      edit_solution_results: [{ id: 1, title: "" }],
      project_image_url: "",
      is_image_present: false,

      delete_modal_status: false,
    };
  }
  componentDidMount() {
    this.get_details();
  }
  get_details = () => {
    this.setState({ loading: true });
    axios
      .get(URLs.get_header_settings)
      .then((response) => {
        if (response.data.status == "1") {
          let currentObj = response.data.data;
          let footer_logo_link = URLs.image_url + currentObj.footer_logo;
          this.setState({
            address: currentObj.footer_address,
            footer_logo_link: footer_logo_link,
            is_image_present: currentObj.footer_logo ? true : false,
            loading: false,
          });
        } else {
          this.setState({ loading: false });
          return;
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
        console.error("Warning:", error);
        // message.warning("Please try again.");
      });
  };
  save_settings = () => {
    if (!this.state.address) {
      message.warning("Address is required");
      return;
    }
    this.setState({ loading: true });
    let formData = new FormData();
    formData.append("address", this.state.address);
    formData.append("footer_logo", this.state.footer_logo);
    axios
      .post(URLs.save_footer_setting, formData)
      .then((response) => {
        if (response.data.status == "1") {
          message.success(response.data.message);
          this.setState(
            {
              loading: false,
            },
            () => this.get_details()
          );
        } else {
          message.warning(response.data.message);
          this.setState({ loading: false });
          return;
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
        console.error("Warning:", error);
        // message.warning("Please try again.");
      });
  };

  render() {
    const columns = [
      {
        title: "Project Name",
        dataIndex: "pname",
        key: "pname",
        width: 150,
      },
      {
        title: "Client Name",
        dataIndex: "cname",
        key: "cname",
      },
      {
        title: "Action",
        dataIndex: "action",
        key: "action",
        render: (item) => {
          return (
            <div>
              <button
                type="button"
                onClick={() => this.open_edit_modal(item)}
                class="btn btn-primary mb-3"
                style={{ width: 50, height: 35, marginRight: 10 }}
              >
                <box-icon
                  name="edit-alt"
                  color="#ffffff"
                  size={"18px"}
                ></box-icon>
              </button>
              <button
                type="button"
                onClick={() => this.open_delete_modal(item)}
                class="btn btn-danger mb-3"
                style={{ width: 50, height: 35 }}
              >
                <box-icon name="trash" color="white" size={"18px"}></box-icon>
              </button>
            </div>
          );
        },
      },
    ];
    const dataSource = this.state.project_list.map((item, index) => {
      return {
        pname: item.name ? item.name : "---",
        cname: item.client_name ? item.client_name : "---",
        action: item,
      };
    });
    return (
      <div class="card">
        <div class="card-header">
          <h4 class="card-title" style={{ fontSize: 16, marginBottom: 0 }}>
            Footer Settings
          </h4>
        </div>
        <Spin spinning={this.state.loading}>
          <div class="card-body p-4">
            <div class="row">
              <div class="col-lg-12">
                <div>
                  <div class="mb-3">
                    <label for="example-text-input" class="form-label">
                      Address <span className="text-danger">*</span>
                    </label>
                    <input
                      class="form-control"
                      type="text"
                      value={this.state.address}
                      onChange={(e) =>
                        this.setState({ address: e.target.value })
                      }
                    />
                  </div>
                  <div class="mb-3">
                    <label for="example-email-input" class="form-label">
                      Footer logo <span class="text-danger">*</span>
                    </label>
                    {this.state.is_image_present == true && (
                      <div
                        style={{
                          padding: 10,
                          border: "1px solid #ddd",
                          width: "fit-content",
                        }}
                        class="mb-2"
                      >
                        <a href={this.state.footer_logo_link} target="_blank">
                          <img
                            src={this.state.footer_logo_link}
                            style={{ width: 100 }}
                          />
                        </a>
                      </div>
                    )}
                    <input
                      class="form-control"
                      type="file"
                      onChange={(e) => {
                        this.setState({ footer_logo: e.target.files[0] });
                      }}
                    />
                  </div>
                  <div class="mt-4">
                    <button
                      type="button"
                      onClick={() => this.save_settings()}
                      class="btn btn-primary w-md"
                    >
                      Update
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Spin>
      </div>
    );
  }
}
