import React, { Component } from "react";
import Header from "./includes/Header";
import Sidebar from "./includes/Sidebar";
import Footer from "./includes/Footer";
import URLs from "./utils/apis";
import axios from "axios";
import { Table, Spin, message } from "antd";
import FullEditor from "ckeditor5-build-full";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import moment from "moment";
import CopyRight from "./includes/CopyRight";

export default class ChangePassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      total_users: 0,
      loading: false,

      title: "",
      author_name: "",
      date: moment().format("YYYY-MM-DD"),
      short_description: "",
      long_description: "",
      banner_img: "",

      old_password: "",
      new_password: "",
      confirm_password: "",
      type1: "password",
      type2: "password",
      type3: "password",
    };
  }
  componentDidMount() {}
  change_password = () => {
    if (!this.state.old_password) {
      message.warning("Old password is required.");
      return;
    }
    if (!this.state.new_password) {
      message.warning("New password is required.");
      return;
    }
    if (!this.state.confirm_password) {
      message.warning("Confirm password is required.");
      return;
    }
    if (this.state.new_password != this.state.confirm_password) {
      message.warning("Both new and confirm passwords must be equal.");
      return;
    }
    this.setState({ loading: true });
    let params = {
      new_password: this.state.new_password,
      confirm_password: this.state.confirm_password,
      id: localStorage.getItem("usecret_id"),
      old_password: this.state.old_password,
    };
    axios
      .post(URLs.change_password, params)
      .then((response) => {
        if (response.data.status == "1") {
          message.success(response.data.message);
          this.setState(
            {
              loading: false,
            },
            () => {
              setTimeout(() => {
                window.location.reload();
              }, 2000);
            }
          );
        } else {
          message.warning(response.data.message);
          this.setState({ loading: false });
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
        console.error("Warning:", error);
      });
  };
  toggle_password = (param) => {
    if (param == "type1") {
      let text = this.state.type1 == "password" ? "text" : "password";
      this.setState({ type1: text });
    } else if (param == "type2") {
      let text = this.state.type2 == "password" ? "text" : "password";
      this.setState({ type2: text });
    } else if (param == "type3") {
      let text = this.state.type3 == "password" ? "text" : "password";
      this.setState({ type3: text });
    }
  };
  render() {
    return (
      <div id="layout-wrapper">
        <Header />
        <Sidebar />
        <div class="main-content">
          <div class="page-content">
            <div class="container-fluid">
              <div class="row">
                <div class="col-12">
                  <div class="page-title-box d-sm-flex align-items-center justify-content-between">
                    <h4 class="mb-sm-0 font-size-18">Change Password</h4>
                    <div class="page-title-right"></div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <div class="card">
                    <Spin spinning={this.state.loading}>
                      <div class="card-header row px-0">
                        <div className="col-lg-8">
                          <div className="mb-4 form-group">
                            <label>
                              Old Password{" "}
                              <span className="text-danger">*</span>
                            </label>
                            <div style={{ position: "relative" }}>
                              <input
                                type={this.state.type1}
                                className="form-control"
                                placeholder="Password"
                                value={this.state.old_password}
                                onChange={(e) =>
                                  this.setState({
                                    old_password: e.target.value,
                                  })
                                }
                              />
                              {this.state.type1 == "password" ? (
                                <box-icon
                                  name="show"
                                  style={{
                                    position: "absolute",
                                    top: 7,
                                    right: 10,
                                    cursor: "pointer",
                                  }}
                                  onClick={() => this.toggle_password("type1")}
                                ></box-icon>
                              ) : (
                                <box-icon
                                  name="low-vision"
                                  style={{
                                    position: "absolute",
                                    top: 7,
                                    right: 10,
                                    cursor: "pointer",
                                  }}
                                  onClick={() => this.toggle_password("type1")}
                                ></box-icon>
                              )}
                            </div>
                          </div>
                          <div className="mb-4 form-group">
                            <label>
                              New Password{" "}
                              <span className="text-danger">*</span>
                            </label>
                            <div style={{ position: "relative" }}>
                              <input
                                type={this.state.type2}
                                className="form-control"
                                placeholder="Password"
                                value={this.state.new_password}
                                onChange={(e) =>
                                  this.setState({
                                    new_password: e.target.value,
                                  })
                                }
                              />
                              {this.state.type2 == "password" ? (
                                <box-icon
                                  name="show"
                                  style={{
                                    position: "absolute",
                                    top: 7,
                                    right: 10,
                                    cursor: "pointer",
                                  }}
                                  onClick={() => this.toggle_password("type2")}
                                ></box-icon>
                              ) : (
                                <box-icon
                                  name="low-vision"
                                  style={{
                                    position: "absolute",
                                    top: 7,
                                    right: 10,
                                    cursor: "pointer",
                                  }}
                                  onClick={() => this.toggle_password("type2")}
                                ></box-icon>
                              )}
                            </div>
                          </div>
                          <div className="mb-4 form-group">
                            <label>
                              Confirm Password{" "}
                              <span className="text-danger">*</span>
                            </label>
                            <div style={{ position: "relative" }}>
                              <input
                                type={this.state.type3}
                                className="form-control"
                                placeholder="Password"
                                value={this.state.confirm_password}
                                onChange={(e) =>
                                  this.setState({
                                    confirm_password: e.target.value,
                                  })
                                }
                              />
                              {this.state.type3 == "password" ? (
                                <box-icon
                                  name="show"
                                  style={{
                                    position: "absolute",
                                    top: 7,
                                    right: 10,
                                    cursor: "pointer",
                                  }}
                                  onClick={() => this.toggle_password("type3")}
                                ></box-icon>
                              ) : (
                                <box-icon
                                  name="low-vision"
                                  style={{
                                    position: "absolute",
                                    top: 7,
                                    right: 10,
                                    cursor: "pointer",
                                  }}
                                  onClick={() => this.toggle_password("type3")}
                                ></box-icon>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4"></div>
                        <div className="col-lg-4">
                          <button
                            type="button"
                            class="btn btn-primary mb-3"
                            style={{
                              width: 170,
                              marginLeft: "auto",
                            }}
                            onClick={() => this.change_password()}
                          >
                            Change Password
                          </button>
                        </div>
                      </div>
                    </Spin>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <CopyRight />
        </div>
      </div>
    );
  }
}
